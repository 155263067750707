import { VStack } from '@chakra-ui/react';
import { EmptyState } from '@jurnee/common/src/components/EmptyState';
import { PartnerDocumentJSON } from '@jurnee/common/src/entities/PartnerDocument';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupedBookings } from '..';
import { Month } from './Month';

interface Props {
  tab: keyof GroupedBookings;
  months: GroupedBookings[keyof GroupedBookings];
  partnerDocuments: PartnerDocumentJSON[];
}

export function BookingsCalendar(props: Props) {
  const { t } = useTranslation('bookings', { keyPrefix: 'tabs' });

  const months = useMemo(
    () => Object.keys(props.months).map(Number).sort((a, b) => props.tab === 'upcoming' ? a - b : b - a),
    [props.tab, props.months]
  );

  if (months.length === 0) {
    return <EmptyState
      icon="listSearch"
      heading={t(`${props.tab}.emptyState.heading`)}
      description={t(`${props.tab}.emptyState.description`)}
      w="100%"
      px={5}
      py="60px"
      bg="white"
      border="1px solid"
      borderColor="gray.200"
      borderRadius={4}
    />;
  }

  return (
    <VStack w="100%" minW={800} spacing={8} alignItems="flex-start">
      {
        months.map(month =>
          <Month
            key={month}
            month={month}
            bookings={props.months[month]}
            partnerDocuments={props.partnerDocuments}
          />
        )
      }
    </VStack>
  );
}
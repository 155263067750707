import { Box, FormControl, FormLabel, HStack, VStack } from '@chakra-ui/react';
import { CountrySelector } from '@jurnee/common/src/components/CountrySelector';
import { InputFormControl } from '@jurnee/common/src/components/InputFormControl';
import { AddressUpsertBody } from '@jurnee/common/src/dtos/address';
import { AddressJSON } from '@jurnee/common/src/entities/Address';
import { useTranslation } from 'react-i18next';

interface Props {
  address?: AddressUpsertBody;
  onChange(address: AddressUpsertBody): void;
}

export function AddressForm(props: Props) {
  const { t } = useTranslation('common');

  function onAddressChange(address: AddressJSON['address']) {
    props.onChange({ ...props.address, address });
  }

  function onCityChange(city: AddressJSON['city']) {
    props.onChange({ ...props.address, city });
  }

  function onPostalCodeChange(postalCode: AddressJSON['postalCode']) {
    props.onChange({ ...props.address, postalCode });
  }

  function onStateChange(state: AddressJSON['state']) {
    props.onChange({ ...props.address, state });
  }

  function onCountryChange(data: { label: string, value: string }) {
    props.onChange({
      ...props.address,
      country: data.label,
      countryCode: data.value
    });
  }

  return (
    <VStack w="100%" spacing={5}>
      <InputFormControl size="sm" name="street" value={props.address?.address} onChange={onAddressChange} isRequired />

      <HStack w="100%" spacing={5}>
        <InputFormControl size="sm" name="city" value={props.address?.city} onChange={onCityChange} isRequired />
        <InputFormControl size="sm" name="postalCode" value={props.address?.postalCode} onChange={onPostalCodeChange} isRequired />
      </HStack>

      <HStack w="100%" spacing={5}>
        <InputFormControl size="sm" name="state" value={props.address?.state} onChange={onStateChange} />

        <FormControl w="100%" id="country" isRequired>
          <FormLabel>{t('fields.country.label')}</FormLabel>

          <Box className="react-select-small">
            <CountrySelector onChange={onCountryChange} value={props.address?.countryCode} />
          </Box>
        </FormControl>
      </HStack>
    </VStack>
  );
}
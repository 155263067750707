import { Box, HStack, Text, VStack, useToast } from '@chakra-ui/react';
import { PartnerDocumentJSON } from '@jurnee/common/src/entities/PartnerDocument';
import PropositionEntity, { PropositionJSON } from '@jurnee/common/src/entities/Proposition';
import { PropositionsGroupJSON } from '@jurnee/common/src/entities/PropositionsGroup';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { deletePartnerRequestProposition } from 'api/partnersRequests';
import { QuoteDrawerData } from 'drawers/PartnerQuoteDrawer';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getPartnerSelector } from 'store/partner/partner.selectors';
import { DotMenu } from './DotMenu';
import { QuoteLine } from './QuoteLine';

interface Props {
  partnerRequestId: number;
  propositionsGroup: PropositionsGroupJSON;
  proposition: PropositionJSON;
  quotes: PartnerDocumentJSON[];
  isEditable: boolean;
  onUpsert(data: QuoteDrawerData): void;
  onDelete(data: { propositionId: PropositionJSON['id']; quoteId: PartnerDocumentJSON['id'] }): void;
}

export function PropositionCard({ partnerRequestId, propositionsGroup, proposition, quotes, isEditable, onUpsert, ...props }: Props) {
  const toast = useToast();
  const { t } = useTranslation('proposition');

  const partner = useSelector(getPartnerSelector);

  const quote = useMemo(
    () => quotes.find(quote => quote.id === proposition.partnerDocumentId),
    [proposition, quotes]
  );

  async function onDelete(propositionId: PropositionEntity['id']) {
    try {
      const { data, relationships: { partnersDocuments } } = await deletePartnerRequestProposition({
        partnerId: partner.id,
        partnerRequestId,
        propositionId
      });

      props.onDelete({ propositionId: data.id, quoteId: partnersDocuments[0]?.id });
      toast(getSuccessToast(t('dotMenu.delete.toasts.success')));
    } catch (err) {
      toast(getErrorToast(t('dotMenu.delete.toasts.error')));
    }
  }

  return (
    <VStack w="100%" spacing={4} p={4} bg="white" border="1px solid" borderColor="gray.200" borderRadius={4}>
      <HStack w="100%" justifyContent="space-between">
        <Text fontSize={16} fontWeight={700}>
          { proposition.name }
        </Text>

        {
          isEditable ?
            <DotMenu
              bookingId={propositionsGroup.bookingId}
              partnerRequestId={partnerRequestId}
              proposition={proposition}
              quote={quote}
              onUpsert={onUpsert}
              onDelete={() => onDelete(proposition.id)}
            /> :
            <Box minW="32px" />
        }
      </HStack>

      <QuoteLine
        propositionsGroup={propositionsGroup}
        proposition={proposition}
        quote={quote}
      />
    </VStack>
  );
}
import { TabPanel, useToast, VStack } from '@chakra-ui/react';
import { PrimaryButton } from '@jurnee/common/src/components/buttons/PrimaryButton';
import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { ProductJSON } from '@jurnee/common/src/entities/Product';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { deleteProduct } from 'api/experiences';
import { ProductDrawer } from 'drawers/ProductDrawer';
import { useTranslation } from 'react-i18next';
import { PackagesTable } from './PackagesTable';

interface Props {
  experience: ExperienceJSON;
  onChange(experience: ExperienceJSON): void;
}

export function Packages(props: Props) {
  const toast = useToast();
  const { t } = useTranslation('products');

  async function onChange(products: ProductJSON[]) {
    return props.onChange({ ...props.experience, products });
  }

  async function onUpsert(product: ProductJSON) {
    const filteredProducts = props.experience.products.filter(({ id }) => id !== product.id);
    onChange([...filteredProducts, product]);
  }

  async function onDelete(product: ProductJSON) {
    try {
      const deletedProduct = await deleteProduct({
        partnerId: props.experience.partner.id,
        experienceId: props.experience.id,
        productId: product.id
      });

      onChange(props.experience.products.filter(({ id }) => id !== deletedProduct.id));
      toast(getSuccessToast(t('toasts.delete.success')));
    } catch(error) {
      toast(getErrorToast(t('toasts.delete.error'), error.message));
    }
  }

  return (
    <TabPanel maxW="1184px">
      <VStack w="100%" spacing={5}>
        {
          props.experience.products.length > 0 &&
            <ProductDrawer experienceId={props.experience.id} onUpsert={onUpsert}>
              <PrimaryButton size="sm" colorScheme="black" alignSelf="flex-end">
                { t('createPackage') }
              </PrimaryButton>
            </ProductDrawer>
        }

        <PackagesTable
          experience={props.experience}
          onUpsert={onUpsert}
          onDelete={onDelete}
        />
      </VStack>
    </TabPanel>
  );
}
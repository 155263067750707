{
  "account": "Account",
  "billing": "Billing",
  "bookings": "Bookings",
  "requests": "Requests",
  "services": "Services",
  "userCard": {
    "logout": "Logout",
    "settings": "Settings"
  }
}
import { api } from '@jurnee/common/src/browser/Api';
import { PartnerPayoutInformationUpsertBody } from '@jurnee/common/src/dtos/partnersPayoutInformation';
import { PartnerJSON } from '@jurnee/common/src/entities/Partner';
import { PartnerPayoutInformationJSON } from '@jurnee/common/src/entities/PartnerPayoutInformation';

export interface PayoutInformationParams {
  partnerId: PartnerJSON['id'];
  payoutInformationId: PartnerPayoutInformationJSON['id'];
  body: PartnerPayoutInformationUpsertBody
}

export function getPayoutInformation(partnerId: PartnerJSON['id']): Promise<PartnerPayoutInformationJSON> {
  return api.get(`/partners/${partnerId}/payout-information`);
}

export function createPayoutInformation({ partnerId, body }: Omit<PayoutInformationParams, 'payoutInformationId'>): Promise<PartnerPayoutInformationJSON> {
  return api.post(`/partners/${partnerId}/payout-information`, body);
}

export function updatePayoutInformation({ partnerId, payoutInformationId, body }: PayoutInformationParams): Promise<PartnerPayoutInformationJSON> {
  return api.put(`/partners/${partnerId}/payout-information/${payoutInformationId}`, body);
}
import { RootStateData } from '@jurnee/common/src/browser/State';
import { createSlice } from '@reduxjs/toolkit';
import { bootstrap } from '../bootstrap/bootstrap.thunks';

const initialState: RootStateData<null> = {
  data: null,
  error: null,
  status: 'INITIAL'
};

const slice = createSlice({
  name: 'partner',
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(bootstrap.pending, () => {
      return {
        data: null,
        error: null,
        status: 'FETCHING'
      };
    });

    builder.addCase(bootstrap.fulfilled, () => {
      return {
        data: null,
        error: null,
        status: 'FETCHED'
      };
    });

    builder.addCase(bootstrap.rejected, () => {
      return {
        data: null,
        error: null,
        status: 'INITIAL'
      };
    });

  }
});

export default slice.reducer;
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

export function getCurrenciesSelector({ currencies }: RootState) {
  return currencies.data;
}

export const getCurrenciesKeys = createSelector(
  (state: RootState) => state.currencies,
  ({ data }: RootState['currencies']) => {
    return Object.keys(data || {}).sort();
  }
);

export function getCurrenciesFetchStatusSelector({ currencies }: RootState) {
  return currencies.status;
}
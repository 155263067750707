import { Box, Divider, Flex, HStack, Text, VStack, useToast } from '@chakra-ui/react';
import { PartnerDocumentJSON } from '@jurnee/common/src/entities/PartnerDocument';
import { PartnerPaymentDocumentJSON } from '@jurnee/common/src/entities/PartnerPaymentDocument';
import { sortByDate } from '@jurnee/common/src/utils/arrays';
import { formatPrice } from '@jurnee/common/src/utils/prices';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import { getErrorToast } from '@jurnee/common/src/utils/toasts';
import { getPaymentDocumentUrl } from 'api/partnerDocuments';
import { PaymentStatus } from 'components/PaymentStatus';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DotMenu } from './DotMenu';
import { PaymentDocumentLine } from './PaymentDocumentLine';

interface Props {
  partnerDocument: PartnerDocumentJSON;
  onDelete(partnerDocument: PartnerDocumentJSON): void;
}

export function InvoiceCard({ partnerDocument, ...props }: Props) {
  const toast = useToast();
  const { t } = useTranslation('invoice');

  const amount = formatPrice(
    partnerDocument.amount,
    partnerDocument.currencyId,
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  );

  const hasReference = partnerDocument.documentReference && !isEmpty(partnerDocument.documentReference);

  async function onDownload(paymentDocumentId: PartnerPaymentDocumentJSON['id']) {
    try {
      const { url } = await getPaymentDocumentUrl({
        partnerId: partnerDocument.partnerId,
        bookingId: partnerDocument.bookingId,
        partnerDocumentId: partnerDocument.id,
        paymentDocumentId
      });

      window.open(url, '_blank');
    } catch(error) {
      toast(getErrorToast(t('paymentDocument.toasts.download.error'), error.message));
    }
  }

  const paymentDocuments = useMemo(() => sortByDate(partnerDocument.partnersPaymentDocuments, 'paidAt'), partnerDocument.partnersPaymentDocuments);

  return (
    <VStack w="100%" spacing={4} p={4} bg="white" border="1px solid" borderColor="gray.200" borderRadius={4}>
      <HStack w="100%" justifyContent="space-between">
        <VStack w="30%" spacing={1} alignItems="flex-start">
          <Text fontSize={16} lineHeight="16px" fontWeight={700}>{amount}</Text>
          <Text fontSize={12} lineHeight="12px" color="gray.400" textTransform="uppercase">{ hasReference ? partnerDocument.documentReference : '-' }</Text>
        </VStack>

        <Box w="30%" />

        <Flex w="30%">
          <PaymentStatus partnerDocuments={[partnerDocument]} />
        </Flex>

        {
          paymentDocuments.length === 0 ?
            <DotMenu onDelete={() => props.onDelete(partnerDocument)} /> :
            <Box minW="32px" />
        }
      </HStack>

      {
        paymentDocuments.map(paymentDocument => (
          <VStack key={paymentDocument.id} w="100%" spacing={4}>
            <Divider />
            <PaymentDocumentLine paymentDocument={paymentDocument} onDownload={onDownload} />
          </VStack>
        ))
      }
    </VStack>
  );
}
import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, HStack, VStack, useToast } from '@chakra-ui/react';
import { InputFormControl } from '@jurnee/common/src/components/InputFormControl';
import { MenuItemWrapper } from '@jurnee/common/src/components/MenuItemWrapper';
import { PrimaryButton } from '@jurnee/common/src/components/buttons/PrimaryButton';
import { SecondaryButton } from '@jurnee/common/src/components/buttons/SecondaryButton';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { getIsFetchingUserSelector, getUserSelector } from 'store/user/user.selectors';
import { updateUser } from 'store/user/user.thunks';

export function UserSettingsDrawer() {
  const user = useSelector(getUserSelector);
  const isLoading = useSelector(getIsFetchingUserSelector);
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { t } = useTranslation(['navbar', 'common', 'toasts']);

  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [email, setEmail] = useState(user.email);
  const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber);
  const [isOpen, setIsOpen] = useState(false);

  function onClose() {
    setIsOpen(false);
  }

  function onOpen() {
    setIsOpen(true);
  }

  async function onSave() {
    try {
      await dispatch(updateUser({
        id: user.id,
        data: {
          firstName,
          lastName,
          email,
          phoneNumber: phoneNumber ? phoneNumber : null
        }
      })).unwrap();

      toast(getSuccessToast(t('toasts:userUpdate.success')));

      onClose();
    } catch (error) {
      toast(getErrorToast(t('toasts:userUpdate.error'), error.message));
    }
  }

  return (
    <>
      <MenuItemWrapper icon='user' label={t('userCard.settings')} onClick={onOpen} />
      <Drawer isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            {t('common:settings')}
            <DrawerCloseButton />
          </DrawerHeader>
          <DrawerBody p={5}>
            <VStack spacing={5}>
              <InputFormControl size="sm" name="firstName" value={firstName} onChange={setFirstName} isRequired/>
              <InputFormControl size="sm" name="lastName" value={lastName} onChange={setLastName} isRequired/>
              <InputFormControl size="sm" name="email" value={email} type="email" onChange={setEmail} isRequired/>
              <InputFormControl size="sm" name="phoneNumber" value={phoneNumber} type="email" onChange={setPhoneNumber} hasHelper/>
            </VStack>
          </DrawerBody>
          <DrawerFooter>
            <HStack justifyContent="space-between" w="100%">
              <SecondaryButton size="sm" colorScheme="pink" onClick={onClose}>{t('common:buttons.close')}</SecondaryButton>
              <PrimaryButton isLoading={isLoading} size="sm" colorScheme="teal" onClick={onSave}>{t('common:buttons.save')}</PrimaryButton>
            </HStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
import { Text, VStack } from '@chakra-ui/react';
import { PrimaryButton } from '@jurnee/common/src/components/buttons/PrimaryButton';
import { PartnerPayoutInformationJSON } from '@jurnee/common/src/entities/PartnerPayoutInformation';
import { PayoutInformationDrawer } from 'drawers/PayoutInformationDrawer';
import { useTranslation } from 'react-i18next';

interface Props {
  onUpsert(payoutInformation: PartnerPayoutInformationJSON): void;
}

export function EmptyState(props: Props) {
  const { t } = useTranslation('payoutInformation');

  return (
    <VStack w="100%" spacing={5} px={5} py="60px" bg="white" border="1px solid" borderColor="gray.200" borderRadius={4}>
      <Text color="gray.400" whiteSpace="pre-line" textAlign="center">{t('emptyState.description')}</Text>

      <PayoutInformationDrawer onUpsert={props.onUpsert}>
        <PrimaryButton size="sm" colorScheme="black">
          { t('buttons.addPayoutInfo') }
        </PrimaryButton>
      </PayoutInformationDrawer>
    </VStack>
  );
}
import { Grid, GridItem } from '@chakra-ui/react';
import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { getCdnImageUrl } from '@jurnee/common/src/utils/core';

interface Props {
  experiencesImages: ExperienceJSON['experiencesImages'];
}

export function ImagesGrid({ experiencesImages }: Props) {
  return (
    <Grid w="100%" templateRows="11" templateColumns="3" gap="1px" h={330}>
      <GridItem rowSpan={8} colSpan={3} borderRadius="4px 4px 0 0" bgSize="cover" bgPosition="center" bgImage={`url('${getCdnImageUrl(experiencesImages[0]?.image.path)}')`} bgColor="gray.200" />
      <GridItem rowSpan={3} borderRadius="0 0 0 4px" bgPosition="center" bgImage={`url('${getCdnImageUrl(experiencesImages[1]?.image.path)}')`} bgSize="cover" bgColor="gray.200" />
      <GridItem rowSpan={3} bgPosition="center" bgImage={`url('${getCdnImageUrl(experiencesImages[2]?.image.path)}')`} bgSize="cover" bgColor="gray.200" />
      <GridItem rowSpan={3} borderRadius="0 0 4px 0" bgSize="cover" bgPosition="center" bgImage={`url('${getCdnImageUrl(experiencesImages[3]?.image.path)}')`} position="relative" bgColor="gray.200" />
    </Grid>
  );
}
import { HStack, TabPanel, useToast, VStack } from '@chakra-ui/react';
import { getLanguages } from '@jurnee/common/src/api/languages';
import { ExperienceDescription } from '@jurnee/common/src/components/ExperienceDetails/Description';
import { ExperienceForm } from '@jurnee/common/src/components/ExperienceDetails/Form';
import { DEFAULT_PARTICIPANTS } from '@jurnee/common/src/components/ExperienceDetails/Form/InformationForm';
import { ExperienceHeader } from '@jurnee/common/src/components/ExperienceDetails/Header';
import { ExperienceInfoAndPolicies } from '@jurnee/common/src/components/ExperienceDetails/InfoAndPolicies';
import { ExperienceMap } from '@jurnee/common/src/components/ExperienceDetails/Map';
import { ExperienceReviews } from '@jurnee/common/src/components/ExperienceDetails/Reviews';
import { Loader } from '@jurnee/common/src/components/Loader';
import { ExperienceJSON, ExperienceRatingJSON, ExperienceStats } from '@jurnee/common/src/entities/Experience';
import { getDefaultProductId, hasMap } from '@jurnee/common/src/utils/experiences';
import { getErrorToast } from '@jurnee/common/src/utils/toasts';
import { getExperienceStats } from 'api/experiences';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getPartnerSelector } from 'store/partner/partner.selectors';

interface Props {
  experience: ExperienceJSON;
  rating: ExperienceRatingJSON;
}

export function Preview({ experience, rating }: Props) {
  const toast = useToast();
  const { t } = useTranslation('service');

  const partner = useSelector(getPartnerSelector);

  const [stats, setStats] = useState<ExperienceStats>(null);
  const [languages, setLanguages] = useState([]);
  const [areReviewsLoading, setAreReviewsLoading] = useState(true);
  const [areLanguagesLoading, setAreLanguagesLoading] = useState(true);

  const [date, setDate] = useState(null);
  const [participants, setParticipants] = useState(DEFAULT_PARTICIPANTS);
  const [languageId, setLanguageId] = useState(null);
  const [productId, setProductId] = useState(getDefaultProductId(experience, participants));

  const hasExperienceMap = hasMap(experience);
  const hasExperienceReviews = !areReviewsLoading && stats.totalReviews > 0;

  async function fetchStats() {
    try {
      const stats = await getExperienceStats(experience.id);
      setStats(stats);
      setAreReviewsLoading(false);
    } catch(error) {
      toast(getErrorToast(t('toasts.fetchExperience.error')));
    }
  }

  async function fetchLanguages() {
    try {
      const { list } = await getLanguages();
      setLanguages(list);
      setAreLanguagesLoading(false);
    } catch(error) {
      toast(getErrorToast(t('toasts.fetchLanguages.error')));
    }
  }

  useEffect(() => {
    fetchStats();
    fetchLanguages();
  }, [experience]);

  return (
    <TabPanel>
      {
        areLanguagesLoading ? (
          <Loader h={400} />
        ) : (
          <VStack maxW="1184px" mx="auto" position="relative" px={8} spacing={8} alignItems="flex-start">
            <ExperienceHeader
              experience={experience}
              rating={rating}
            />

            <HStack spacing={16} w="100%" alignItems="stretch">
              <ExperienceDescription experience={experience} />

              <VStack w="100%" maxW={500} spacing={5}>
                <ExperienceForm
                  experience={experience}
                  productId={productId}
                  participants={participants}
                  currency={'EUR'}
                  targetCurrency={{ id: 'EUR', targetExchangeRates: [] }}
                  date={date}
                  languages={languages}
                  languageId={languageId}
                  isSubmitDisabled={true}
                  onParticipantsChange={setParticipants}
                  onDateChange={setDate}
                  onTimeChange={() => null}
                  onLanguageChange={setLanguageId}
                  onProductChange={setProductId}
                  onCityChange={() => null}
                  onInfoNext={() => null}
                />
              </VStack>
            </HStack>

            { hasExperienceMap && <ExperienceMap address={experience.partner.address} /> }

            {
              hasExperienceReviews &&
                <ExperienceReviews
                  experience={experience}
                  rating={rating}
                  stats={stats}
                  partnersProvidersInformation={partner.partnersProvidersInformation}
                />
            }

            <ExperienceInfoAndPolicies experience={experience} />
          </VStack>
        )
      }
    </TabPanel>
  );
}
import { Heading, VStack, useToast } from '@chakra-ui/react';
import { Loader } from '@jurnee/common/src/components/Loader';
import { PartnerDocumentJSON } from '@jurnee/common/src/entities/PartnerDocument';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { deletePartnerDocument, getPartnerDocuments } from 'api/partnerDocuments';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getPartnerSelector } from 'store/partner/partner.selectors';
import { Invoices } from './Invoices';

export function InvoicesList() {
  const toast = useToast();
  const { t } = useTranslation('billing');

  const partner = useSelector(getPartnerSelector);

  const [partnerDocuments, setPartnerDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  async function fetchPartnerDocuments() {
    try {
      const { list } = await getPartnerDocuments(partner.id);
      setPartnerDocuments(list);
    } catch(error) {
      toast(getErrorToast(t('toasts.fetchPartnerDocuments.error')));
    }

    setIsLoading(false);
  }

  useEffect(() => {
    fetchPartnerDocuments();
  }, []);

  async function onDelete(partnerDocument: PartnerDocumentJSON) {
    try {
      const deletedPartnerDocument = await deletePartnerDocument({
        partnerId: partnerDocument.partnerId,
        bookingId: partnerDocument.bookingId,
        partnerDocumentId: partnerDocument.id
      });

      setPartnerDocuments(partnerDocuments.filter(({ id }) => id !== deletedPartnerDocument.id));
      toast(getSuccessToast(t('invoice:dotMenu.delete.toasts.success')));
    } catch(error) {
      toast(getErrorToast(t('invoice:dotMenu.delete.toasts.error'), error.message));
    }
  }

  return (
    <VStack w="100%" spacing={2} alignItems="flex-start">
      <Heading size="md">{t('invoices.title')}</Heading>

      {
        isLoading ?
          <Loader
            w="100%"
            px={5}
            py="60px"
            bg="white"
            border="1px solid"
            borderColor="gray.200"
            borderRadius={4}
          /> :
          <Invoices
            partnerDocuments={partnerDocuments}
            onDelete={onDelete}
          />
      }
    </VStack>
  );
}
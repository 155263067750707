import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { PrimaryButton } from '@jurnee/common/src/components/buttons/PrimaryButton';
import { SecondaryButton } from '@jurnee/common/src/components/buttons/SecondaryButton';
import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { isDescriptionValid, isImagesCountValid, isProductCountValid } from '@jurnee/common/src/utils/experiences';
import { hasValidAccountInfo } from '@jurnee/common/src/utils/partners';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { publishExperience } from 'api/experiences';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getPartnerSelector } from 'store/partner/partner.selectors';
import { Check } from './Check';

interface Props {
  experience: ExperienceJSON;
  onPublish(experience: ExperienceJSON): void;
}

export function PublishExperienceModal(props: Props) {
  const toast = useToast();
  const { t } = useTranslation(['service', 'common']);

  const partner = useSelector(getPartnerSelector);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const isPublishDisabled =
    !isDescriptionValid(props.experience) ||
    !isImagesCountValid(props.experience) ||
    !isProductCountValid(props.experience) ||
    !hasValidAccountInfo(partner);

  const [isSaving, setIsSaving] = useState(false);

  async function onPublish() {
    setIsSaving(true);

    try {
      const experience = await publishExperience({
        partnerId: partner.id,
        experienceId: props.experience.id
      });

      setIsSaving(false);
      props.onPublish(experience);
      toast(getSuccessToast(t('toasts.publishExperience.success')));
      onClose();
    } catch(err) {
      setIsSaving(false);
      toast(getErrorToast(t('toasts.publishExperience.error')));
    }
  }

  return (
    <>
      <PrimaryButton size="sm" colorScheme="black" onClick={onOpen}>
        { t('publish') }
      </PrimaryButton>

      <Modal size="lg" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            { t('modals.publish.title') }
            <ModalCloseButton />
          </ModalHeader>

          <ModalBody>
            <VStack w="100%" spacing={4} alignItems="flex-start">
              <Text>{t('modals.publish.checksInfo')}</Text>

              <VStack w="100%" pl={3} spacing={2} alignItems="flex-start">
                <Check
                  label={t('modals.publish.descriptionCondition')}
                  isValid={isDescriptionValid(props.experience)}
                />

                <Check
                  label={t('modals.publish.photosCondition')}
                  isValid={isImagesCountValid(props.experience)}
                />

                <Check
                  label={t('modals.publish.packagesCondition')}
                  isValid={isProductCountValid(props.experience)}
                />

                <Check
                  label={t('modals.publish.accountInfoCondition')}
                  isValid={hasValidAccountInfo(partner)}
                />
              </VStack>

              <Text>{t('modals.publish.oncePublishedInfo')}</Text>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing={4} justifyContent="space-between" w="100%">
              <SecondaryButton colorScheme="gray" size="sm" onClick={onClose}>
                { t('common:buttons.close') }
              </SecondaryButton>

              <PrimaryButton colorScheme="blue" size="sm" onClick={onPublish} isLoading={isSaving} isDisabled={isPublishDisabled}>
                { t('common:buttons.publish') }
              </PrimaryButton>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
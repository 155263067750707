import { Box } from '@chakra-ui/react';
import { DotMenu as Menu } from '@jurnee/common/src/components/DotMenu';
import { MenuItemWrapper } from '@jurnee/common/src/components/MenuItemWrapper';
import { PartnerRequestJSON } from '@jurnee/common/src/entities/PartnerRequest';
import { DeclineModal } from '@jurnee/common/src/modals/DeclineModal';
import { isPropositionsGroupDraft } from '@jurnee/common/src/utils/propositionsGroups';
import { useTranslation } from 'react-i18next';

interface Props {
  partnerRequest: PartnerRequestJSON;
  onDecline(partnerRequest: PartnerRequestJSON, reason: string): Promise<void>;
}

export function DotMenu({ partnerRequest, onDecline }: Props) {
  const { t } = useTranslation(['request', 'common']);

  const isEditable = isPropositionsGroupDraft(partnerRequest.propositionsGroup) && !partnerRequest.declinedAt;

  return (
    <Box onClick={e => e.stopPropagation()}>
      <Menu size="sm" color="black" placement="bottom-end">
        <DeclineModal
          title={t('modals.decline.title')}
          message={t('modals.decline.message')}
          reasonLabel={t('modals.decline.reason.label')}
          reasonPlaceholder={t('modals.decline.reason.placeholder')}
          button={t('common:buttons.decline')}
          onDecline={reason => onDecline(partnerRequest, reason)}
        >
          <MenuItemWrapper icon="squareRoundedX" label={t('common:buttons.decline')} isDisabled={!isEditable} />
        </DeclineModal>
      </Menu>
    </Box>
  );
}
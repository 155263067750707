import { Box } from '@chakra-ui/react';
import { DotMenu as Menu } from '@jurnee/common/src/components/DotMenu';
import { MenuItemWrapper } from '@jurnee/common/src/components/MenuItemWrapper';
import { ProductJSON } from '@jurnee/common/src/entities/Product';
import { ConfirmModal } from '@jurnee/common/src/modals/ConfirmModal';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  product: ProductJSON;
  isDeletable: boolean;
  onDelete(product: ProductJSON): void;
}

export function DotMenu({ product, isDeletable, onDelete }: Props) {
  const { t } = useTranslation(['common', 'products']);

  return (
    <Box onClick={e => e.stopPropagation()}>
      <Menu size="sm" color="black" placement="bottom-end">
        <ConfirmModal
          title={t('products:dotMenu.deleteConfirmation.title')}
          message={<Trans i18nKey='products:dotMenu.deleteConfirmation.message' values={{ name: product.name }} />}
          buttonLabel={t('common:buttons.delete')}
          onConfirm={() => onDelete(product)}
        >
          <MenuItemWrapper icon="trash" label={t('buttons.delete')} isDisabled={!isDeletable}/>
        </ConfirmModal>
      </Menu>
    </Box>
  );
}
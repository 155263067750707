import { Box, HStack } from '@chakra-ui/react';
import { Loader } from '@jurnee/common/src/components/Loader';
import { Navbar } from 'components/Navbar';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { getIsAuthenticatedSelector } from 'store/auth/auth.selectors';
import { getIsBootstrappedSelector, getShouldBootstrapSelector } from 'store/bootstrap/bootstrap.selectors';
import { bootstrap } from 'store/bootstrap/bootstrap.thunks';
import { useAppDispatch } from '../store';

export function ProtectedRoute() {
  const isAuthenticated = useSelector(getIsAuthenticatedSelector);
  const isBootstrapped = useSelector(getIsBootstrappedSelector);
  const shouldBootstrap = useSelector(getShouldBootstrapSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (shouldBootstrap) {
      dispatch(bootstrap());
    }
  }, [shouldBootstrap]);

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if (!isBootstrapped) {
    return <Loader overlay />;
  }

  return (
    <HStack p={0} spacing={0} h="100%">
      <Navbar />
      <Box h="100%" w="100%" overflowY="auto" position="relative" borderLeft="1px solid" borderColor="gray.200">
        <Outlet />
      </Box>
    </HStack>
  );
}
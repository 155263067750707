import { PartnerUserJSON } from '@jurnee/common/src/entities/PartnerUser';
import { RootState } from 'store';
import { partnerUsersAdapter } from './partnerUsers.adapter';

export const partnerUsersSelectors = partnerUsersAdapter.getSelectors(
  (state: RootState) => state.partnerUsers
);

export const getPartnerUserById = (id: PartnerUserJSON['id']) => {
  return (state: RootState) => partnerUsersSelectors.selectById(state, id);
};
import { ErrorPage } from '@jurnee/common/src/components/ErrorPage';
import { Account } from 'pages/Account';
import { Billing } from 'pages/Billing';
import { Booking } from 'pages/Booking';
import { Bookings } from 'pages/Bookings';
import { ProtectedRoute } from 'pages/ProtectedRoute';
import { Request } from 'pages/Request';
import { Requests } from 'pages/Requests';
import { Service } from 'pages/Service';
import { Services } from 'pages/Services';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import { Login } from './pages/Login';

export const router = createBrowserRouter([{
  path: '/',
  element: <ProtectedRoute />,
  children: [{
    path: '/',
    element: <Navigate to='/bookings' />,
  }, {
    path: '/account',
    element: <Account />,
  }, {
    path: '/billing',
    element: <Billing />,
  }, {
    path: '/bookings',
    element: <Bookings />,
  }, {
    path: '/bookings/:bookingId',
    element: <Booking />,
  }, {
    path: '/requests',
    element: <Requests />,
  }, {
    path: '/requests/:partnerRequestId',
    element: <Request />,
  }, {
    path: '/services',
    element: <Services />,
  }, {
    path: '/services/:experienceId',
    element: <Service />,
  }],
  errorElement: <ErrorPage />
}, {
  path: '/login',
  element: <Login />,
}]);
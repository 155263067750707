import { PartnerRequestCommentJSON, PartnerRequestCommentRelationshipsJSON } from '@jurnee/common/src/entities/PartnerRequestComment';
import { Expand, List } from '@jurnee/common/src/serializers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CommentCreateParams, PartnerRequestParams, createPartnerRequestComment, getPartnerRequestComments } from 'api/partnersRequests';
import { RootState } from 'store';

export const getPartnerRequestCommentsThunk = createAsyncThunk<List<PartnerRequestCommentJSON, PartnerRequestCommentRelationshipsJSON>, PartnerRequestParams, { state: RootState }>('PARTNER_REQUEST_COMMENTS_FETCH', async (params, thunkAPI) => {
  try {
    const partnerRequestComments = await getPartnerRequestComments(params);
    return partnerRequestComments;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const createPartnerRequestCommentThunk = createAsyncThunk<Expand<PartnerRequestCommentJSON, PartnerRequestCommentRelationshipsJSON>, CommentCreateParams, { state: RootState }>('PARTNER_REQUEST_COMMENTS_CREATE', async (params, thunkAPI) => {
  try {
    const partnerRequestComment = await createPartnerRequestComment(params);
    return partnerRequestComment;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});
import { RootStateData } from '@jurnee/common/src/browser/State';
import { PartnerJSON } from '@jurnee/common/src/entities/Partner';
import { createSlice } from '@reduxjs/toolkit';
import { bootstrap } from 'store/bootstrap/bootstrap.thunks';
import { createPayoutInformationThunk } from 'store/payoutInformation/payoutInformation.thunks';
import { login } from '../auth/auth.thunks';
import { updatePartnerAddressThunk, updatePartnerThunk } from './partner.thunks';

const initialState: RootStateData<PartnerJSON> = {
  data: null,
  error: null,
  status: 'INITIAL'
};

const slice = createSlice({
  name: 'partner',
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(login.fulfilled, (state, action) => {
      return {
        data: action.payload.partner,
        error: null,
        status: 'FETCHED'
      };
    });

    builder.addCase(bootstrap.fulfilled, (state, action) => {
      return {
        data: action.payload.partner,
        error: null,
        status: 'FETCHED'
      };
    });

    builder.addCase(updatePartnerThunk.fulfilled, (state, action) => {
      return {
        data: action.payload,
        error: null,
        status: 'FETCHED'
      };
    });

    builder.addCase(updatePartnerAddressThunk.fulfilled, (state, action) => {
      return {
        data: {
          ...state.data,
          address: action.payload
        },
        error: null,
        status: 'FETCHED'
      };
    });

    builder.addCase(createPayoutInformationThunk.fulfilled, (state, { payload: { id } }) => {
      return {
        ...state,
        data: {
          ...state.data,
          partnersPayoutInformation: { id }
        }
      };
    });

  }
});

export default slice.reducer;
import { StatusBadge } from '@jurnee/common/src/components/StatusBadge';
import { isPropositionsGroupClosed, isPropositionsGroupOpen } from '@jurnee/common/src/utils/propositionsGroups';
import { GroupedRequest } from 'pages/Requests';
import { useTranslation } from 'react-i18next';

interface Props {
  request: GroupedRequest;
}

export function Status({ request }: Props) {
  const { t } = useTranslation('request', { keyPrefix: 'status' });

  const { partnerRequest, propositions } = request;

  function getStatus() {
    if (partnerRequest.declinedAt) {
      return 'DECLINED';
    }

    if (isPropositionsGroupClosed(partnerRequest.propositionsGroup)) {
      if (propositions.length === 0) {
        return 'CLOSED';
      }

      if (propositions.some(proposition => proposition.selectedAt)) {
        return 'SELECTED';
      }

      return 'REJECTED';
    }

    if (isPropositionsGroupOpen(partnerRequest.propositionsGroup)) {
      if (propositions.length === 0) {
        return 'CLOSED';
      }

      return 'PENDING_SELECTION';
    }

    if (propositions.length === 0) {
      return 'WAITING_PROPOSAL';
    }

    return 'PROPOSAL_SENT';
  }

  function getStatusProps() {
    const status = getStatus();
    const label = t(status);

    switch(status) {
    case 'SELECTED':
    case 'PROPOSAL_SENT':
      return { label, color: 'teal' };
    case 'PENDING_SELECTION':
      return { label, color: 'blue' };
    case 'REJECTED':
    case 'WAITING_PROPOSAL':
      return { label, color: 'red' };
    default:
      return { label, color: 'gray' };
    }
  }

  return <StatusBadge {...getStatusProps()} />;
}
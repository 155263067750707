import { Box, HStack, Heading, Image, Text, VStack } from '@chakra-ui/react';
import { SecondaryButton } from '@jurnee/common/src/components/buttons/SecondaryButton';
import { PlaceDTO } from '@jurnee/common/src/dtos/places';
import { PartnerJSON } from '@jurnee/common/src/entities/Partner';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PlaceSearch } from './PlaceSearch';

interface Props {
  providerInformation?: PartnerJSON['partnersProvidersInformation'][number];
  onChange(place: PlaceDTO): void;
}

export function GoogleMapsAccount({ onChange, providerInformation }: Props) {
  const { t } = useTranslation(['account', 'common']);

  if (providerInformation) {
    return (
      <VStack w="100%" p={8} bg="white" border="1px solid" borderColor="gray.200" borderRadius={4} spacing={5} alignItems="flex-start">
        <HStack spacing={5}>
          <HStack h={16} w={16} justifyContent="center" alignItems="center" bg="gray.50" border="1px solid" borderColor="gray.200" borderRadius={4}>
            <Image src="assets/images/google-map.png" />
          </HStack>
          <VStack alignItems="flex-start" spacing={1}>
            <Heading size="md">{t('account:googleMaps.linked')}</Heading>
            <Text color="gray.500">{t('account:googleMaps.providerId')}: {providerInformation.providerId}</Text>
          </VStack>
        </HStack>
        <Box color="gray.400" w="100%">
          <Link to={providerInformation.url} target="_blank">
            <SecondaryButton size="sm">
              {t('common:buttons.view')}
            </SecondaryButton>
          </Link>
        </Box>
      </VStack>
    );
  }

  return (
    <VStack w="100%" p={8} bg="white" border="1px solid" borderColor="gray.200" borderRadius={4} spacing={5} alignItems="flex-start">
      <HStack spacing={5}>
        <HStack h={16} w={16} justifyContent="center" alignItems="center" bg="gray.50" border="1px solid" borderColor="gray.200" borderRadius={4}>
          <Image src="assets/images/google-map.png" />
        </HStack>
        <VStack alignItems="flex-start" spacing={1}>
          <Heading size="md">{t('account:googleMaps.heading')}</Heading>
          <Text color="gray.500">{t('account:googleMaps.text')}</Text>
        </VStack>
      </HStack>
      <Box w="100%" className="react-select-small">
        <PlaceSearch onChange={onChange} />
      </Box>
    </VStack>
  );
}
import CurrencyEntity, { CurrencyExchangeRates } from '../entities/Currency';
import PartnerDocumentEntity, { PartnerDocumentJSON } from '../entities/PartnerDocument';

export type PaymentStatus = 'TO_BE_INVOICED' | 'PROCESSING' | 'PAID_PARTIALLY' | 'PAID_FULLY';

export function getTotalAmount(amounts: Pick<PartnerDocumentJSON, 'amount'>[]) {
  return amounts.reduce((total, { amount }) => {
    return total + (amount || 0);
  }, 0);
}

export function getPartnerInvoicesTotal(partnersDocuments: Pick<PartnerDocumentEntity, 'amount' | 'currencyId' | 'type'>[], exchangeRates: CurrencyExchangeRates, currency: CurrencyEntity['id']) {
  return partnersDocuments.reduce((total, document) => {
    const amount = document.amount ?? 0;
    const exchangeRate = exchangeRates[document.currencyId][currency] ?? 1;

    return document.type === 'INVOICE' ? total + amount * exchangeRate : total;
  }, 0);
}

export function getPaidPercentage(partnerDocuments: PartnerDocumentJSON[]) {
  const totalAmount = getTotalAmount(partnerDocuments);
  const paymentDocuments = partnerDocuments.flatMap(({ partnersPaymentDocuments }) => partnersPaymentDocuments);
  const paidAmount = getTotalAmount(paymentDocuments);

  return Math.round(paidAmount / totalAmount * 100);
}

export function getPaymentStatus(partnerDocuments: PartnerDocumentJSON[]): PaymentStatus {
  if (partnerDocuments.length === 0) {
    return 'TO_BE_INVOICED';
  }

  if (partnerDocuments.some(({ partnersPaymentDocuments }) => partnersPaymentDocuments.length === 0)) {
    return 'PROCESSING';
  }

  const paidPercentage = getPaidPercentage(partnerDocuments);

  return paidPercentage < 100 ? 'PAID_PARTIALLY' : 'PAID_FULLY';
}
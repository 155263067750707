{
  "buttons": {
    "addPayoutInfo": "Add my payout information"
  },
  "drawer": {
    "title": "Payout information",
    "toasts": {
      "create": {
        "error": "An error occurred while adding payout information",
        "success": "Payout information added"
      },
      "update": {
        "error": "An error occurred while updating payout information",
        "success": "Payout information updated"
      }
    }
  },
  "emptyState": {
    "description": "You didn't provided your payout information.\nWe need it in order to make your payments."
  },
  "title": "Payout information",
  "toasts": {
    "fetchPayoutInfo": {
      "error": "An error occurred while retrieving payout information"
    }
  }
}
import { VStack } from '@chakra-ui/react';
import { EmptyState } from '@jurnee/common/src/components/EmptyState';
import { PartnerDocumentJSON } from '@jurnee/common/src/entities/PartnerDocument';
import { InvoiceCard } from 'components/InvoiceCard';
import { useTranslation } from 'react-i18next';

interface Props {
  partnerDocuments: PartnerDocumentJSON[];
  onDelete(partnerDocument: PartnerDocumentJSON): void;
}

export function Invoices({ partnerDocuments, onDelete }: Props) {
  const { t } = useTranslation('billing');

  if (partnerDocuments.length === 0) {
    return <EmptyState
      icon="listSearch"
      heading={t('invoices.emptyState.heading')}
      description={t('invoices.emptyState.description')}
      w="100%"
      px={5}
      py="60px"
      bg="white"
      border="1px solid"
      borderColor="gray.200"
      borderRadius={4}
    />;
  }

  return (
    <VStack w="100%" spacing={4}>
      {
        partnerDocuments.map(partnerDocument =>
          <InvoiceCard
            key={partnerDocument.id}
            partnerDocument={partnerDocument}
            onDelete={onDelete}
          />
        )
      }
    </VStack>
  );
}
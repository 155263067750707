{
  "dotMenu": {
    "delete": {
      "label": "Delete invoice",
      "modal": {
        "message": "Are you sure you want to delete this invoice?",
        "title": "Delete invoice"
      },
      "toasts": {
        "error": "An error occurred while deleting the invoice",
        "success": "Invoice deleted"
      }
    }
  },
  "drawer": {
    "form": {
      "amount": {
        "label": "Total price including taxes",
        "placeholder": "1234.56"
      },
      "document": {
        "label": "Document"
      },
      "dueDate": {
        "label": "Due date",
        "placeholder": "Select a date"
      },
      "reference": {
        "label": "Invoice reference",
        "placeholder": "INVOICE-12345"
      }
    },
    "title": "Upload an invoice",
    "toasts": {
      "error": "An error occurred while uploading the invoice",
      "success": "Invoice uploaded"
    }
  },
  "paymentDocument": {
    "amount": "Amount",
    "paidAt": "Paid at",
    "paidBy": "Pay by",
    "toasts": {
      "download": {
        "error": "An error occurred while downloading the document"
      }
    },
    "type": {
      "CREDIT_CARD": "Credit card",
      "WIRE_TRANSFER": "Wire transfer"
    }
  },
  "status": {
    "PAID_FULLY": "Paid 100%",
    "PAID_PARTIALLY": "Paid {{percentage}}%",
    "PROCESSING": "Processing",
    "TO_BE_INVOICED": "To be invoiced"
  }
}
import { Text, VStack } from '@chakra-ui/react';
import { PrimaryButton } from '@jurnee/common/src/components/buttons/PrimaryButton';
import { PartnerQuoteDrawer, QuoteDrawerData } from 'drawers/PartnerQuoteDrawer';
import { useTranslation } from 'react-i18next';

interface Props {
  bookingId: number;
  partnerRequestId: number;
  onUpsert(data: QuoteDrawerData): void;
}

export function EmptyState({ bookingId, partnerRequestId, onUpsert }: Props) {
  const { t } = useTranslation('request', { keyPrefix: 'propositions' });

  return (
    <VStack w="100%" spacing={5} px={5} py="60px" bg="white" border="1px solid" borderColor="gray.200" borderRadius={4}>
      <Text color="gray.400" whiteSpace="pre-line" textAlign="center">{t('emptyState.description')}</Text>

      <PartnerQuoteDrawer
        bookingId={bookingId}
        partnerRequestId={partnerRequestId}
        onUpsert={onUpsert}
      >
        <PrimaryButton size="sm" colorScheme="black">
          { t('createQuote') }
        </PrimaryButton>
      </PartnerQuoteDrawer>
    </VStack>
  );
}
import { MenuItemWrapper } from '@jurnee/common/src/components/MenuItemWrapper';
import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { DotButton } from 'components/DotButton';
import { ExperienceDrawer } from 'drawers/ExperienceDrawer';
import { useTranslation } from 'react-i18next';

interface Props {
  experience: ExperienceJSON;
  onUpdate(experience: ExperienceJSON): void;
}

export function DotMenu({ experience, onUpdate }: Props) {
  const { t } = useTranslation('service');

  return (
    <DotButton>
      <ExperienceDrawer experience={experience} onUpsert={onUpdate}>
        <MenuItemWrapper icon="edit" label={t('dotMenu.edit.label')} />
      </ExperienceDrawer>
    </DotButton>
  );
}
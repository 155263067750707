import { Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { ProductJSON } from '@jurnee/common/src/entities/Product';
import { sortAlphabeticallyBy } from '@jurnee/common/src/utils/arrays';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyListState } from './EmptyListState';
import { Row } from './Row';

interface Props {
  experience: ExperienceJSON;
  onUpsert(product: ProductJSON): void;
  onDelete(product: ProductJSON): void;
}

export function PackagesTable({ experience, onUpsert, onDelete }: Props) {
  const { t } = useTranslation('products');

  const products = useMemo(
    () => sortAlphabeticallyBy(experience.products, 'name'),
    [experience.products]
  );

  return (
    <TableContainer w="100%">
      <Table>
        <Thead>
          <Tr>
            <Th w="25%">{t('fields.name')}</Th>
            <Th w="65%">{t('fields.description')}</Th>
            <Th w="10%">{t('fields.maxParticipants')}</Th>
            <Th w={8}></Th>
          </Tr>
        </Thead>

        <Tbody>
          {
            experience.products.length === 0 ? (
              <EmptyListState experienceId={experience.id} onUpsert={onUpsert} />
            ) : (
              products.map(product =>
                <Row
                  key={product.id}
                  experience={experience}
                  product={product}
                  onUpsert={onUpsert}
                  onDelete={onDelete}
                />
              )
            )
          }
        </Tbody>
      </Table>
    </TableContainer>
  );
}
import { Text, VStack } from '@chakra-ui/react';
import { StatusBadge } from '@jurnee/common/src/components/StatusBadge';
import { PartnerDocumentJSON } from '@jurnee/common/src/entities/PartnerDocument';
import { useTranslation } from 'react-i18next';

interface Props {
  partnerDocuments: PartnerDocumentJSON[];
}

type Status = 'ACTION_REQUIRED' | 'PENDING' | 'CONFIRMED';

function getStatus(partnerDocuments: PartnerDocumentJSON[]): Status {
  if (partnerDocuments.length === 0) {
    return 'ACTION_REQUIRED';
  }

  if (partnerDocuments.some(({ partnersPaymentDocuments }) => partnersPaymentDocuments.length === 0)) {
    return 'PENDING';
  }

  return 'CONFIRMED';
}

function getColor(status: Status) {
  if (status === 'ACTION_REQUIRED') {
    return 'red';
  }

  if (status === 'PENDING') {
    return 'blue';
  }

  return 'teal';
}

export function Status({ partnerDocuments }: Props) {
  const { t } = useTranslation('booking');

  const status = getStatus(partnerDocuments);
  const color = getColor(status);

  return (
    <VStack spacing={2} alignItems="flex-start">
      <Text fontWeight={700} lineHeight="16px" noOfLines={1}>{t('fields.status')}</Text>
      <StatusBadge label={t(`status.${status}`)} color={color} />
    </VStack>
  );
}
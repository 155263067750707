import { Box, Input, Text, VStack } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { ContentType, IMAGE_CONTENT_TYPES } from '@jurnee/common/src/constants/ContentTypes';
import { resizeImage } from '@jurnee/common/src/utils/images';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onAdd(files: File[]): void;
}

const IMAGE_MAX_WIDTH = 1200;

export function ImageInput({ onAdd }: Props) {
  const { t } = useTranslation('service', { keyPrefix: 'tabs.photos' });

  const fileInput = useRef(null);

  function handleClick() {
    fileInput.current.click();
  }

  async function handleFile(fileList: FileList) {
    if (!fileList[0]) {
      return null;
    }

    const files = await Promise.all(Array.from(fileList).map(async (image) => {
      if (IMAGE_CONTENT_TYPES.includes(image.type as ContentType)) {
        const resizedImage = await resizeImage(image, IMAGE_MAX_WIDTH);
        return resizedImage;
      }

      return image;
    }));

    return onAdd(files);
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.stopPropagation();
    e.preventDefault();

    return handleFile(e.target.files);
  }

  return (
    <Box
      w="100%"
      aspectRatio={1}
      border="1px dashed"
      borderRadius={4}
      bgColor="white"
      borderColor="black"
      alignContent="center"
      textAlign="center"
      cursor="pointer"
      onClick={handleClick}
      _hover={{
        borderColor: 'gray.600',
        color: 'gray.600',
        'svg path': { fill: 'gray.600' }
      }}
    >
      <VStack spacing={0}>
        <Icon icon="photoUp" />
        <Text>{ t('addPhotos') }</Text>
      </VStack>

      <Input
        type="file"
        accept={IMAGE_CONTENT_TYPES.join(', ')}
        onChange={handleChange}
        ref={fileInput}
        multiple
        hidden
      />
    </Box>
  );
}
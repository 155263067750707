import { LinkBox, LinkOverlay, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { DEFAULT_UTC_TIMEZONE } from '@jurnee/common/src/entities/Address';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { PartnerDocumentJSON } from '@jurnee/common/src/entities/PartnerDocument';
import { getBookingParticipants, getMainBookingRelation } from '@jurnee/common/src/utils/bookings';
import { getItemAddress } from '@jurnee/common/src/utils/bookingsItems';
import { formatTime, getDayOfMonth, getDayOfWeekPrefix } from '@jurnee/common/src/utils/dates';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Field } from './Field';
import { Payment } from './Payment';
import { Price } from './Price';
import { Status } from './Status';

interface Props {
  booking: BookingJSON;
  partnerDocuments: PartnerDocumentJSON[];
}

export function BookingCard(props: Props) {
  const { t } = useTranslation(['booking', 'common']);

  const bookingItem = getMainBookingRelation(props.booking);
  const address = getItemAddress(bookingItem);
  const timeZone = address?.timezone || DEFAULT_UTC_TIMEZONE;
  const startingAt = formatTime(new Date(bookingItem.from), { timeZone, displayTimeZone: true });
  const location = bookingItem.locationType === 'VIRTUAL' ? t('common:virtual') : address?.city;

  const partnerDocuments = useMemo(
    () => props.partnerDocuments.filter(({ bookingId }) => bookingId === props.booking.id),
    [props.booking, props.partnerDocuments]
  );

  return (
    <LinkBox w="100%" display="flex">
      <VStack minW="65px" py="14px" spacing={1} alignItems="center" alignContent="center" bg="black" borderRadius="4px 0 0 4px">
        <Text lineHeight="14px" color="white" fontWeight={400}>
          { getDayOfWeekPrefix(new Date(bookingItem.from)) }
        </Text>

        <Text fontSize={20} lineHeight="20px" color="white" fontWeight={700}>
          { getDayOfMonth(new Date(bookingItem.from)) }
        </Text>
      </VStack>

      <SimpleGrid w="100%" justifyContent="space-between" columns={7} spacing={2} px={5} pr="10px" py={3} bg="white" border="1px solid" borderColor="gray.200" borderRadius="0 4px 4px 0">
        <Field label={t('fields.reference')} value={`#${props.booking.id}`} />
        <Field label={t('fields.startingAt')} value={startingAt} />
        <Field label={t('fields.participants')} value={getBookingParticipants(props.booking)} />
        <Field label={t('fields.location')} value={location} />
        <Price partnerDocuments={partnerDocuments} />
        <Status partnerDocuments={partnerDocuments} />
        <Payment partnerDocuments={partnerDocuments} />
      </SimpleGrid>

      <LinkOverlay to={`/bookings/${props.booking.id}`} as={Link} />
    </LinkBox>
  );
}

import { AddressJSON } from '@jurnee/common/src/entities/Address';
import { PartnerJSON } from '@jurnee/common/src/entities/Partner';
import { PartnerProviderInformationJSON } from '@jurnee/common/src/entities/PartnerProviderInformation';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PartnerAddressCreatePayload, PartnerAddressUpdatePayload, PartnerProviderInformationCreatePayload, PartnerUpdatePayload, createPartnerAddress, createPartnerProviderInformation, updatePartner, updatePartnerAddress } from 'api/partner';
import { RootState } from '..';

export const updatePartnerThunk = createAsyncThunk<PartnerJSON, PartnerUpdatePayload, { state: RootState }>('UPDATE_PARTNER', async (payload, thunkAPI) => {
  try {
    const partner = await updatePartner(payload);

    return partner;
  } catch (error) {
    return thunkAPI.rejectWithValue({ message: error.message });
  }
});

export const updatePartnerAddressThunk = createAsyncThunk<AddressJSON, PartnerAddressUpdatePayload, { state: RootState }>('UPDATE_PARTNER_ADDRESS', async (payload, thunkAPI) => {
  try {
    const partnerAddress = await updatePartnerAddress(payload);

    return partnerAddress;
  } catch (error) {
    return thunkAPI.rejectWithValue({ message: error.message });
  }
});

export const createPartnerAddressThunk = createAsyncThunk<AddressJSON, PartnerAddressCreatePayload, { state: RootState }>('CREATE_PARTNER_ADDRESS', async (payload, thunkAPI) => {
  try {
    const partnerAddress = await createPartnerAddress(payload);

    return partnerAddress;
  } catch (error) {
    return thunkAPI.rejectWithValue({ message: error.message });
  }
});

export const createPartnerProviderInformationThunk = createAsyncThunk<PartnerProviderInformationJSON, PartnerProviderInformationCreatePayload, { state: RootState }>('CREATE_PARTNER_PROVIDER_INFORMATION', async (payload, thunkAPI) => {
  try {
    const partnerAddress = await createPartnerProviderInformation(payload);

    return partnerAddress;
  } catch (error) {
    return thunkAPI.rejectWithValue({ message: error.message });
  }
});
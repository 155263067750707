import { Heading, VStack } from '@chakra-ui/react';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { PartnerDocumentJSON } from '@jurnee/common/src/entities/PartnerDocument';
import { sortByBookingsItemsFrom } from '@jurnee/common/src/utils/bookings';
import { getMonthAndYear } from '@jurnee/common/src/utils/dates';
import { useMemo } from 'react';
import { BookingCard } from './BookingCard';

interface Props {
  month: number;
  bookings: BookingJSON[];
  partnerDocuments: PartnerDocumentJSON[];
}

export function Month(props: Props) {
  const bookings = useMemo(() => sortByBookingsItemsFrom(props.bookings), [props.bookings]);

  return (
    <VStack w="100%" spacing={4} alignItems="flex-start">
      <Heading size="md" lineHeight="20px">
        { getMonthAndYear(new Date(props.month)) }
      </Heading>

      {
        bookings.map(booking =>
          <BookingCard
            key={booking.id}
            booking={booking}
            partnerDocuments={props.partnerDocuments}
          />
        )
      }
    </VStack>
  );
}

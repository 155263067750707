import { Divider, HStack, Heading, StackProps, Text, VStack } from '@chakra-ui/react';
import { DEFAULT_UTC_TIMEZONE } from '@jurnee/common/src/entities/Address';
import { PropositionsGroupJSON } from '@jurnee/common/src/entities/PropositionsGroup';
import { getCityAndCountry } from '@jurnee/common/src/utils/addresses';
import { formatDate } from '@jurnee/common/src/utils/dates';
import { useTranslation } from 'react-i18next';

interface Props {
  request: PropositionsGroupJSON;
}

export function Details({ request, ...inheritedProps }: Props & StackProps) {
  const { t } = useTranslation(['request', 'common']);

  const from = formatDate(
    new Date(request.from),
    {
      displayTime: true,
      displayTimeZone: true,
      timeZone: request.address?.timezone ?? DEFAULT_UTC_TIMEZONE
    }
  );

  return (
    <VStack spacing={2} alignItems="flex-start" {...inheritedProps}>
      <Heading size="md">{t('details.title')}</Heading>

      <VStack w="100%" spacing={3} p={4} bg="white" border="1px solid" borderColor="gray.200" borderRadius={4} alignItems="flex-start">
        <VStack w="100%" spacing={1}>
          <HStack w="100%" justifyContent="space-between">
            <Text fontWeight={700}>
              { t('fields.eventDate') }
            </Text>

            <Text color="gray.400">
              { from }
            </Text>
          </HStack>

          <HStack w="100%" justifyContent="space-between">
            <Text fontWeight={700}>
              { t('fields.location') }
            </Text>

            <Text color="gray.400">
              { request.address ? getCityAndCountry(request.address) : '-' }
            </Text>
          </HStack>

          <HStack w="100%" justifyContent="space-between">
            <Text fontWeight={700}>
              { t('fields.participants') }
            </Text>

            <Text color="gray.400">
              { request.participants } { t('common:people') }
            </Text>
          </HStack>
        </VStack>

        <Divider />

        <Text whiteSpace="pre-line">
          { request.content }
        </Text>
      </VStack>
    </VStack>
  );
}
import { Heading, VStack } from '@chakra-ui/react';
import { BookingItemJSON } from '@jurnee/common/src/entities/BookingItem';
import { sortByDate } from '@jurnee/common/src/utils/arrays';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BookingItemCard } from './BookingItemCard';

interface Props {
  bookingItems: BookingItemJSON[];
}

export function BookingItems(props: Props) {
  const { t } = useTranslation('booking');

  const bookingItems = useMemo(() => sortByDate(props.bookingItems, 'from'), [props.bookingItems]);

  return (
    <VStack w="100%" spacing={2} alignItems="flex-start">
      <Heading size="md">{t('eventSchedule.title')}</Heading>

      <VStack w="100%" spacing={4}>
        { bookingItems.map(bookingItem => <BookingItemCard key={bookingItem.id} bookingItem={bookingItem} />) }
      </VStack>
    </VStack>
  );
}
import { PartnerUserJSON, PartnerUserUpdate } from '@jurnee/common/src/entities/PartnerUser';
import { createAsyncThunk } from '@reduxjs/toolkit';
import * as api from 'api/user';
import { RootState } from '..';

interface PartnerUserUpdatePayload {
  id: PartnerUserJSON['id'];
  data: PartnerUserUpdate;
}

export const updateUser = createAsyncThunk<PartnerUserJSON, PartnerUserUpdatePayload, { state: RootState }>('UPDATE_USER', async ({ id, data }, thunkAPI) => {
  try {
    const partnerUser = await api.updateUser(id, data);

    return partnerUser;
  } catch (error) {
    return thunkAPI.rejectWithValue({ message: error.message });
  }
});
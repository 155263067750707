import { Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, HStack, VStack, useDisclosure, useToast } from '@chakra-ui/react';
import { InputFormControl } from '@jurnee/common/src/components/InputFormControl';
import { PrimaryButton } from '@jurnee/common/src/components/buttons/PrimaryButton';
import { SecondaryButton } from '@jurnee/common/src/components/buttons/SecondaryButton';
import { PartnerPayoutInformationUpsertBody } from '@jurnee/common/src/dtos/partnersPayoutInformation';
import { PartnerPayoutInformationJSON } from '@jurnee/common/src/entities/PartnerPayoutInformation';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { updatePayoutInformation } from 'api/payoutInformation';
import { cloneElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { getPartnerSelector } from 'store/partner/partner.selectors';
import { createPayoutInformationThunk } from 'store/payoutInformation/payoutInformation.thunks';
import { AddressForm } from '../../components/AddressForm';

interface Props {
  payoutInformation?: PartnerPayoutInformationJSON;
  children: React.ReactElement;
  onUpsert(payoutInformation: PartnerPayoutInformationJSON): void;
}

export function PayoutInformationDrawer(props: Props) {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { t } = useTranslation(['payoutInformation', 'common']);

  const partner = useSelector(getPartnerSelector);

  const { isOpen, onClose, onOpen } = useDisclosure();
  const [isSaving, setIsSaving] = useState(false);

  const [accountNumber, setAccountNumber] = useState(props.payoutInformation?.accountNumber ?? null);
  const [bankCode, setBankCode] = useState(props.payoutInformation?.bankCode ?? null);
  const [bicSwift, setBicSwift] = useState(props.payoutInformation?.bicSwift ?? null);
  const [companyName, setCompanyName] = useState(props.payoutInformation?.companyName ?? null);
  const [iban, setIban] = useState(props.payoutInformation?.iban ?? null);
  const [registrationNumber, setRegistrationNumber] = useState(props.payoutInformation?.registrationNumber ?? null);
  const [routingNumber, setRoutingNumber] = useState(props.payoutInformation?.routingNumber ?? null);
  const [vatNumber, setVatNumber] = useState(props.payoutInformation?.vatNumber ?? null);

  const [address, setAddress] = useState({
    address: props.payoutInformation?.address.address ?? null,
    city: props.payoutInformation?.address.city ?? null,
    country: props.payoutInformation?.address.country ?? null,
    countryCode: props.payoutInformation?.address.countryCode ?? null,
    postalCode: props.payoutInformation?.address.postalCode ?? null,
    state: props.payoutInformation?.address.state ?? null
  });

  const isSaveDisabled = [
    bicSwift,
    companyName,
    address?.address,
    address?.city,
    address?.country,
    address?.countryCode,
    address?.postalCode,
  ].some(isEmpty);

  const body: PartnerPayoutInformationUpsertBody = {
    accountNumber,
    bankCode,
    bicSwift,
    companyName,
    iban,
    registrationNumber,
    routingNumber,
    vatNumber,
    address
  };

  async function update() {
    try {
      const payoutInformation = await updatePayoutInformation({
        partnerId: partner.id,
        payoutInformationId: props.payoutInformation.id,
        body
      });

      props.onUpsert(payoutInformation);

      onClose();
      return toast(getSuccessToast(t('drawer.toasts.update.success')));
    } catch(error) {
      return toast(getErrorToast(t('drawer.toasts.update.error'), error));
    }
  }

  async function create() {
    try {
      const payoutInformation = await dispatch(createPayoutInformationThunk({
        partnerId: partner.id,
        body
      })).unwrap();

      props.onUpsert(payoutInformation);

      onClose();
      return toast(getSuccessToast(t('drawer.toasts.create.success')));
    } catch(error) {
      return toast(getErrorToast(t('drawer.toasts.create.error'), error));
    }
  }

  async function onSave() {
    setIsSaving(true);

    await (props.payoutInformation ? update() : create());

    setIsSaving(false);
  }

  return (
    <>
      { cloneElement(props.children, { onClick: onOpen }) }

      <Drawer isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            { t('title') }
            <DrawerCloseButton />
          </DrawerHeader>

          <DrawerBody p={5}>
            <VStack spacing={5}>
              <InputFormControl
                size="sm"
                name="legalCompanyName"
                value={companyName}
                onChange={setCompanyName}
                isRequired
              />

              <HStack spacing={5}>
                <InputFormControl
                  size="sm"
                  name="vatNumber"
                  value={vatNumber}
                  onChange={setVatNumber}
                />

                <InputFormControl
                  size="sm"
                  name="registrationNumber"
                  value={registrationNumber}
                  onChange={setRegistrationNumber}
                />
              </HStack>

              <Divider />

              <AddressForm address={address} onChange={setAddress} />

              <Divider />

              <InputFormControl
                size="sm"
                name="iban"
                value={iban}
                onChange={setIban}
              />

              <HStack spacing={5}>
                <InputFormControl
                  size="sm"
                  name="accountNumber"
                  value={accountNumber}
                  onChange={setAccountNumber}
                />

                <InputFormControl
                  size="sm"
                  name="bicSwift"
                  value={bicSwift}
                  onChange={setBicSwift}
                  isRequired
                />
              </HStack>

              <HStack spacing={5}>
                <InputFormControl
                  size="sm"
                  name="bankCode"
                  value={bankCode}
                  onChange={setBankCode}
                />

                <InputFormControl
                  size="sm"
                  name="routingNumber"
                  value={routingNumber}
                  onChange={setRoutingNumber}
                />
              </HStack>
            </VStack>
          </DrawerBody>

          <DrawerFooter>
            <HStack justifyContent="space-between" w="100%">
              <SecondaryButton size="sm" colorScheme="pink" onClick={onClose}>{t('common:buttons.close')}</SecondaryButton>
              <PrimaryButton size="sm" colorScheme="teal" onClick={onSave} isLoading={isSaving} isDisabled={isSaveDisabled}>{t('common:buttons.save')}</PrimaryButton>
            </HStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
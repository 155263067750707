import { Text, VStack } from '@chakra-ui/react';

interface Props {
  label: string;
  value: string | number;
}

export function Field({ label, value }: Props) {
  return (
    <VStack spacing={2} alignItems="flex-start" display="inline-grid">
      <Text fontWeight={700} lineHeight="16px" noOfLines={1}>{label}</Text>
      <Text color="gray.400" lineHeight="16px" noOfLines={1}>{value ?? '-'}</Text>
    </VStack>
  );
}
import { HStack, Heading, TabPanel, Text, Textarea, VStack, useToast } from '@chakra-ui/react';
import { PrimaryButton } from '@jurnee/common/src/components/buttons/PrimaryButton';
import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { updateExperience } from 'api/experiences';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  experience: ExperienceJSON;
  onChange(experience: ExperienceJSON): void
}

const DESC_MAX_LENGTH = 2000;

export function Description(props: Props) {
  const toast = useToast();
  const { t } = useTranslation('service');

  const [description, setDescription] = useState(props.experience.description.trim());
  const [isSaving, setIsSaving] = useState(false);
  const isDescriptionInvalid = isEmpty(description) || description.length > DESC_MAX_LENGTH;

  async function onSave() {
    setIsSaving(true);

    try {
      const experience = await updateExperience({
        partnerId: props.experience.partner.id,
        experienceId: props.experience.id,
        body: { description }
      });

      props.onChange(experience);
      toast(getSuccessToast(t('toasts.updateExperience.success')));
    } catch(error) {
      toast(getErrorToast(t('toasts.updateExperience.error')));
    }

    setIsSaving(false);
  }

  return (
    <TabPanel maxW={700} display="flex" flexDirection="column" gap={3} pt={8}>
      <HStack w="100%" justifyContent="space-between">
        <Heading lineHeight="32px">{t('tabs.description.label')}</Heading>

        <PrimaryButton size="sm" colorScheme="teal" isDisabled={isDescriptionInvalid} isLoading={isSaving} onClick={onSave}>
          { t('common:buttons.save') }
        </PrimaryButton>
      </HStack>

      <VStack w="100%" spacing={4}>
        <Textarea
          size="sm"
          p={4}
          height={400}
          placeholder={t('tabs.description.placeholder')}
          resize="none"
          value={description}
          onChange={e => setDescription(e.target.value)}
        />

        <Text alignSelf="flex-end" color={description.length > DESC_MAX_LENGTH ? 'red.500' : 'gray.400'}>
          { t('tabs.description.maxLength', { count: description.length, max: DESC_MAX_LENGTH }) }
        </Text>
      </VStack>
    </TabPanel>
  );
}
import { PartnerRequestJSON } from '@jurnee/common/src/entities/PartnerRequest';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PartnerRequestDeclineParams, declinePartnerRequest } from 'api/partnersRequests';
import { RootState } from 'store';

export const declinePartnerRequestThunk = createAsyncThunk<PartnerRequestJSON, PartnerRequestDeclineParams, { state: RootState }>('PARTNER_REQUESTS_DECLINE', async (params, thunkAPI) => {
  try {
    const partnerRequest = await declinePartnerRequest(params);
    return partnerRequest;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});
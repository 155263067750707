import { Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormHelperText, FormLabel, HStack, Input, Text, Textarea, VStack, useToast } from '@chakra-ui/react';
import { PrimaryButton } from '@jurnee/common/src/components/buttons/PrimaryButton';
import { SecondaryButton } from '@jurnee/common/src/components/buttons/SecondaryButton';
import { NumberInput } from '@jurnee/common/src/components/NumberInput';
import { Currency } from '@jurnee/common/src/entities/Currency';
import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { PricingJSON } from '@jurnee/common/src/entities/Pricing';
import { ProductJSON } from '@jurnee/common/src/entities/Product';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { createProduct, updateProduct } from 'api/experiences';
import { cloneElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getPartnerSelector } from 'store/partner/partner.selectors';

interface Props {
  experienceId: ExperienceJSON['id'];
  product?: ProductJSON;
  pricing?: PricingJSON;
  isOpen?: boolean;
  children?: React.ReactElement;
  onUpsert(product: ProductJSON): void;
  onClose?(): void;
}

const NAME_MAX_LENGTH = 50;
const DESCRIPTION_MAX_LENGTH = 100;

export function ProductDrawer(props: Props) {
  const toast = useToast();
  const { t } = useTranslation(['products', 'common']);

  const partner = useSelector(getPartnerSelector);

  const [isOpen, setIsOpen] = useState(!!props.isOpen);
  const [isSaving, setIsSaving] = useState(false);

  const [name, setName] = useState<ProductJSON['name']>(props.product?.name ?? '');
  const [description, setDescription] = useState<ProductJSON['description']>(props.product?.description ?? '');
  const [threshold, setThreshold] = useState<PricingJSON['threshold']>(props.pricing?.threshold ?? null);

  const isNameInvalid = isEmpty(name) || name.length > NAME_MAX_LENGTH;
  const isDescriptionInvalid = isEmpty(description) || description.length > DESCRIPTION_MAX_LENGTH;
  const isThresholdInvalid = !threshold || threshold <= 0;

  const isSaveDisabled = isNameInvalid || isDescriptionInvalid || isThresholdInvalid;

  function onOpen(e?: React.MouseEvent) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    setIsOpen(true);
  }

  function onClose() {
    props.onClose && props.onClose();

    setIsOpen(false);
    setIsSaving(false);
  }

  useEffect(() => {
    if (props.isOpen) {
      onOpen();
    }
  }, [props.isOpen]);

  const productBody = {
    name,
    description,
    currency: 'EUR' as Currency,
    fixedPrice: 0,
    minSpent: 0
  };

  async function create() {
    try {
      const product = await createProduct({
        partnerId: partner.id,
        experienceId: props.experienceId,
        body: {
          ...productBody,
          pricings: [{
            groupPrice: 0,
            threshold,
            unitPrice: 0
          }]
        }
      });

      onClose();
      props.onUpsert(product);
      toast(getSuccessToast(t('toasts.create.success')));
    } catch(err) {
      toast(getErrorToast(t('toasts.create.error')));
    }
  }

  async function update() {
    try {
      const product = await updateProduct({
        partnerId: partner.id,
        experienceId: props.product.experienceId,
        productId: props.product.id,
        body: {
          ...productBody,
          pricings: [{ ...props.pricing, threshold }]
        }
      });

      onClose();
      props.onUpsert(product);
      toast(getSuccessToast(t('toasts.update.success')));
    } catch(err) {
      toast(getErrorToast(t('toasts.update.error')));
    }
  }

  async function onSave() {
    setIsSaving(true);

    props.product ? await update() : await create();

    setIsSaving(false);
  }

  function onThresholdChange(value: string) {
    const threshold = Number(value);

    if (isNaN(threshold)) {
      return;
    }

    setThreshold(threshold);
  }

  return (
    <>
      { props.children && cloneElement(props.children, { onClick: onOpen }) }

      <Drawer isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            { t(`drawer.title.${props.product ? 'edit' : 'create'}`) }
            <DrawerCloseButton />
          </DrawerHeader>

          <DrawerBody p={5}>
            <VStack spacing={5}>
              <Text color="gray.400">{t('drawer.description')}</Text>

              <Divider />

              <FormControl isRequired>
                <HStack w="100%" justifyContent="space-between">
                  <FormLabel>{t('fields.name')}</FormLabel>

                  <FormHelperText
                    mb="4px"
                    color={name.length > NAME_MAX_LENGTH ? 'red.500' : 'gray.400'}
                  >
                    { t('drawer.maxLength', { count: name.length, max: NAME_MAX_LENGTH }) }
                  </FormHelperText>
                </HStack>

                <Input
                  size="sm"
                  name="name"
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>{t('drawer.form.maxParticipants.label')}</FormLabel>

                <NumberInput
                  size="sm"
                  name="maxParticipants"
                  defaultValue={threshold}
                  onChange={onThresholdChange}
                />
              </FormControl>

              <FormControl isRequired>
                <HStack w="100%" justifyContent="space-between">
                  <FormLabel>{t('fields.description')}</FormLabel>

                  <FormHelperText
                    mb="4px"
                    color={description.length > DESCRIPTION_MAX_LENGTH ? 'red.500' : 'gray.400'}
                  >
                    { t('drawer.maxLength', { count: description.length, max: DESCRIPTION_MAX_LENGTH }) }
                  </FormHelperText>
                </HStack>

                <Textarea
                  size="sm"
                  name="description"
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                />
              </FormControl>
            </VStack>
          </DrawerBody>

          <DrawerFooter>
            <HStack justifyContent="space-between" w="100%">
              <SecondaryButton size="sm" colorScheme="pink" onClick={onClose}>
                { t('common:buttons.close') }
              </SecondaryButton>

              <PrimaryButton size="sm" colorScheme="teal" onClick={onSave} isLoading={isSaving} isDisabled={isSaveDisabled}>
                { t('common:buttons.save') }
              </PrimaryButton>
            </HStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
import { RootStateData } from '@jurnee/common/src/browser/State';
import { PartnerStatsJSON } from '@jurnee/common/src/dtos/partners';
import { createSlice } from '@reduxjs/toolkit';
import { declinePartnerRequestThunk } from 'store/partnerRequests/partnerRequests.thunks';
import { getPartnerStatsThunk } from './partnerStats.thunks';

const initialState: RootStateData<PartnerStatsJSON> = {
  data: null,
  error: null,
  status: 'INITIAL'
};

const slice = createSlice({
  name: 'partnerStats',
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getPartnerStatsThunk.pending, () => {
      return { data: null, error: null, status: 'FETCHING' };
    });

    builder.addCase(getPartnerStatsThunk.fulfilled, (state, { payload }) => {
      return { data: payload, error: null, status: 'FETCHED' };
    });

    builder.addCase(getPartnerStatsThunk.rejected, () => {
      return { data: null, error: true, status: 'INITIAL' };
    });

    builder.addCase(declinePartnerRequestThunk.fulfilled, (state) => {
      return {
        ...state,
        data: {
          ...state.data,
          partnerRequests: {
            ...state.data.partnerRequests,
            pending: Math.max(state.data.partnerRequests.pending - 1, 0)
          }
        }
      };
    });

  }
});

export default slice.reducer;
{
  "heading": "Billing",
  "invoices": {
    "emptyState": {
      "description": "You didn't upload any invoices yet",
      "heading": "No invoices"
    },
    "title": "Invoices & payouts"
  },
  "toasts": {
    "fetchPartnerDocuments": {
      "error": "An error occurred while retrieving your invoices"
    }
  }
}
import { RootStateData } from '@jurnee/common/src/browser/State';
import { PartnerUserJSON } from '@jurnee/common/src/entities/PartnerUser';
import { createSlice } from '@reduxjs/toolkit';
import { bootstrap } from 'store/bootstrap/bootstrap.thunks';
import { login } from '../auth/auth.thunks';
import { updateUser } from './user.thunks';

const initialState: RootStateData<PartnerUserJSON> = {
  data: null,
  error: null,
  status: 'INITIAL'
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(login.fulfilled, (state, action) => {
      return {
        data: action.payload.user,
        error: null,
        status: 'FETCHED'
      };
    });

    builder.addCase(bootstrap.fulfilled, (state, action) => {
      return {
        data: action.payload.user,
        error: null,
        status: 'FETCHED'
      };
    });

    builder.addCase(updateUser.pending, (state) => {
      return {
        ...state,
        status: 'FETCHING',
        error: null
      };
    });

    builder.addCase(updateUser.fulfilled, (state, action) => {
      return {
        error: null,
        status: 'FETCHED',
        data: action.payload
      };
    });

    builder.addCase(updateUser.rejected, (state) => {
      return {
        ...state,
        error: true,
        status: 'FETCHED'
      };
    });

  }
});

export default slice.reducer;
import { Heading, VStack, useToast } from '@chakra-ui/react';
import { CommentForm, CommentFormData } from '@jurnee/common/src/components/comments/CommentForm';
import { PartnerRequestCommentJSON } from '@jurnee/common/src/entities/PartnerRequestComment';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { uploadPartnerRequestCommentAttachment } from 'api/partnersRequests';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { getPartnerSelector } from 'store/partner/partner.selectors';
import { createPartnerRequestCommentThunk, getPartnerRequestCommentsThunk } from 'store/partnerRequestComments/partnerRequestComments.thunks';
import { Comment } from './Comment';

interface Props {
  partnerRequestId: number;
}

export function Comments({ partnerRequestId }: Props) {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { t } = useTranslation('request');

  const partner = useSelector(getPartnerSelector);
  const [comments, setComments] = useState<PartnerRequestCommentJSON[]>([]);
  const [isSaving, setIsSaving] = useState(false);

  async function fetchComments() {
    try {
      const { list } = await dispatch(getPartnerRequestCommentsThunk({
        partnerId: partner.id,
        partnerRequestId
      })).unwrap();

      setComments(list);
    } catch(err) {
      toast(getErrorToast(t('toasts.fetchComments.error')));
    }
  }

  useEffect(() => {
    fetchComments();
  }, [partnerRequestId]);

  async function onSubmit({ content, files }: CommentFormData) {
    setIsSaving(true);

    try {
      const comment = await dispatch(createPartnerRequestCommentThunk({
        partnerId: partner.id,
        partnerRequestId,
        data: { content }
      })).unwrap();

      const attachments = await Promise.all(files.map(file => {
        return uploadPartnerRequestCommentAttachment({
          partnerId: partner.id,
          partnerRequestId,
          commentId: comment.data.id
        }, file);
      }));

      setComments([{ ...comment.data, attachments }, ...comments]);
      toast(getSuccessToast(t('toasts.createComment.success')));
    } catch(err) {
      toast(getErrorToast(t('toasts.createComment.error')));
    }

    setIsSaving(false);
  }

  return (
    <VStack w="100%" spacing={2} alignItems="flex-start">
      <Heading size="md">{t('comments.title')}</Heading>

      <VStack w="100%" spacing={4}>
        <CommentForm isUploading={isSaving} onSubmit={onSubmit} hasFileInput />

        {
          comments.map(comment =>
            <Comment
              key={comment.id}
              comment={comment}
              attachments={comment.attachments}
            />
          )
        }
      </VStack>
    </VStack>
  );
}
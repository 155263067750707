import { Box, HStack, Heading, VStack } from '@chakra-ui/react';
import { PayoutInformationCard } from 'components/PayoutInformationCard';
import { useTranslation } from 'react-i18next';
import { InvoicesList } from './InvoicesList';

export function Billing() {
  const { t } = useTranslation('billing');

  return (
    <VStack w="100%" h="100%" spacing={0}>
      <Box w="100%" p={8} bg="white" borderBottom="1px solid" borderColor="gray.200">
        <Heading lineHeight="32px">{t('heading')}</Heading>
      </Box>

      <HStack w="100%" maxW="1184px" p={8} spacing={8} alignItems="flex-start">
        <VStack w="66%" minW={400} spacing={8}>
          <InvoicesList />
        </VStack>

        <VStack w="33%" minW={340} spacing={8}>
          <PayoutInformationCard />
        </VStack>
      </HStack>
    </VStack>
  );
}
import { Tbody } from '@chakra-ui/react';
import { PartnerRequestJSON } from '@jurnee/common/src/entities/PartnerRequest';
import { GroupedRequest, TabKey } from 'pages/Requests';
import { EmptyListState } from './EmptyListState';
import { Row } from './Row';

interface Props {
  tab: TabKey;
  requests: GroupedRequest[];
  onDecline(partnerRequest: PartnerRequestJSON, reason: string): Promise<void>;
}

export function Body(props: Props) {
  if (props.requests.length === 0) {
    return <EmptyListState tab={props.tab} />;
  }

  return (
    <Tbody>
      {
        props.requests.map(request =>
          <Row
            key={request.partnerRequest.id}
            request={request}
            onDecline={props.onDecline}
          />
        )
      }
    </Tbody>
  );
}

import { VStack } from '@chakra-ui/react';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { PartnerDocumentJSON } from '@jurnee/common/src/entities/PartnerDocument';
import { sortByDate } from '@jurnee/common/src/utils/arrays';
import { InvoiceCard } from 'components/InvoiceCard';
import { useMemo } from 'react';
import { EmptyState } from './EmptyState';

interface Props {
  bookingId: BookingJSON['id'];
  partnerDocuments: PartnerDocumentJSON[];
  onCreate(partnerDocument: PartnerDocumentJSON): void;
  onDelete(partnerDocument: PartnerDocumentJSON): void;
}

export function InvoicesList({ bookingId, partnerDocuments, onCreate, onDelete }: Props) {
  if (partnerDocuments.length === 0) {
    return <EmptyState bookingId={bookingId} onCreate={onCreate} />;
  }

  const sortedPartnerDocuments = useMemo(() => sortByDate(partnerDocuments, 'createdAt'), [partnerDocuments]);

  return (
    <VStack w="100%" spacing={4}>
      {
        sortedPartnerDocuments.map(partnerDocument =>
          <InvoiceCard
            key={partnerDocument.id}
            partnerDocument={partnerDocument}
            onDelete={onDelete}
          />
        )
      }
    </VStack>
  );
}
import { RootStateData } from '@jurnee/common/src/browser/State';
import { CurrencyExchangeRates } from '@jurnee/common/src/entities/Currency';
import { createSlice } from '@reduxjs/toolkit';
import { getCurrenciesThunk } from './currencies.thunks';

const initialState: RootStateData<CurrencyExchangeRates> = {
  data: null,
  error: null,
  status: 'INITIAL'
};

const slice = createSlice({
  name: 'currencies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getCurrenciesThunk.pending, () => {
      return {
        data: null,
        error: null,
        status: 'FETCHING'
      };
    });

    builder.addCase(getCurrenciesThunk.fulfilled, (_, { payload }) => {
      return {
        data: payload,
        error: null,
        status: 'FETCHED'
      };
    });

    builder.addCase(getCurrenciesThunk.rejected, () => {
      return {
        data: null,
        error: true,
        status: 'INITIAL'
      };
    });

  }
});

export default slice.reducer;
{
  "buttons": {
    "uploadInvoice": "Upload an invoice"
  },
  "eventSchedule": {
    "participants": "{{count}} people",
    "title": "Event schedule",
    "virtual": "Virtual"
  },
  "fields": {
    "location": "Location",
    "participants": "Participants",
    "payment": "Payment",
    "price": "Price",
    "reference": "Reference",
    "startingAt": "Starting at",
    "status": "Status"
  },
  "heading": "Booking",
  "invoices": {
    "emptyState": {
      "description": "You have not uploaded any invoices yet.\nUpload your invoice to get paid."
    },
    "title": "Invoices"
  },
  "status": {
    "ACTION_REQUIRED": "Action required",
    "CONFIRMED": "Confirmed",
    "PENDING": "Pending"
  },
  "toasts": {
    "fetchBooking": {
      "error": "An error occurred while retrieving the booking"
    },
    "fetchPartnerDocuments": {
      "error": "An error occurred while retrieving invoices"
    }
  }
}
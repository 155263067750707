import { useToast } from '@chakra-ui/react';
import { CommentCard } from '@jurnee/common/src/components/comments/CommentCard';
import { PartnerRequestCommentJSON } from '@jurnee/common/src/entities/PartnerRequestComment';
import { PartnerRequestCommentAttachmentJSON } from '@jurnee/common/src/entities/PartnerRequestCommentAttachment';
import { getErrorToast } from '@jurnee/common/src/utils/toasts';
import { getPartnerRequestCommentAttachmentUrl } from 'api/partnersRequests';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getPartnerSelector } from 'store/partner/partner.selectors';
import { getPartnerUserById } from 'store/partnerUsers/partnerUsers.selectors';

interface Props {
  comment: PartnerRequestCommentJSON;
  attachments: PartnerRequestCommentAttachmentJSON[];
}

export function Comment({ comment, attachments }: Props) {
  const toast = useToast();
  const { t } = useTranslation('request');

  const partner = useSelector(getPartnerSelector);
  const partnerUser = useSelector(getPartnerUserById(comment.partnerUserId));

  async function onDownload(attachmentId: number) {
    try {
      const { url } = await getPartnerRequestCommentAttachmentUrl({
        partnerId: partner.id,
        partnerRequestId: comment.partnerRequestId,
        commentId: comment.id,
        attachmentId
      });

      window.open(url, '_blank');
    } catch(error) {
      toast(getErrorToast(t('toasts.downloadFile.error'), error.message));
    }
  }

  return <CommentCard
    comment={comment}
    user={partnerUser}
    attachments={attachments}
    onDownload={onDownload}
  />;
}
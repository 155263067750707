import { getPlacesAutoComplete } from '@jurnee/common/src/api/places';
import { logger } from '@jurnee/common/src/browser/Logger';
import { SearchSelect } from '@jurnee/common/src/components/Select';
import { PlaceDTO } from '@jurnee/common/src/dtos/places';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onChange(place: PlaceDTO): void;
}

export function PlaceSearch({ onChange }: Props) {
  const { t } = useTranslation('account', { keyPrefix: 'googleMaps' });

  const [places, setPlaces] = useState<PlaceDTO[]>([]);
  const [input, setInput] = useState('');

  async function search() {
    try {
      const { list } = await getPlacesAutoComplete({ input, isDetailed: true });

      setPlaces(list);
    } catch (err) {
      logger.error(err);
    }
  }

  useEffect(() => {
    if (input.length > 0) {
      const timer = window.setTimeout(search, 1000);

      return () => window.clearInterval(timer);
    }
  }, [input]);

  const options = places.map((place) => ({
    label: place.description,
    img: place.details.photos.length > 0 ? place.details.photos[0] : null,
    value: place
  }));

  return (
    <SearchSelect<PlaceDTO>
      options={options}
      openMenuOnFocus={false}
      maxMenuHeight={300}
      placeholder={t('placeholder')}
      onInputChange={setInput}
      onChange={({ value }) => onChange(value)}
      noOptionsMessage={() => null}
      isDisabled={false}
      filterOption={() => true}
    />
  );
}
import { Box, Card } from '@chakra-ui/react';
import { EmptyState } from '@jurnee/common/src/components/EmptyState';
import { PrimaryButton } from '@jurnee/common/src/components/buttons/PrimaryButton';
import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { ExperienceDrawer } from 'drawers/ExperienceDrawer';
import { useTranslation } from 'react-i18next';

interface Props {
  onUpsert(experience: ExperienceJSON): void
}

export function EmptyStateCard({ onUpsert }: Props) {
  const { t } = useTranslation('services');

  return (
    <Box w="100%" p={8}>
      <Card p={16}>
        <EmptyState
          icon="listSearch"
          heading={t('emptyState.heading')}
          description={t('emptyState.description')}
        >
          <ExperienceDrawer onUpsert={onUpsert}>
            <PrimaryButton size="sm" colorScheme="black">
              { t('createService') }
            </PrimaryButton>
          </ExperienceDrawer>
        </EmptyState>
      </Card>
    </Box>
  );
}
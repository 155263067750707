import { api } from '@jurnee/common/src/browser/Api';
import { BookingJSON, PartnerBookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { PartnerJSON } from '@jurnee/common/src/entities/Partner';
import { List } from '@jurnee/common/src/serializers';

export function getBookings(partnerId: PartnerJSON['id']): Promise<List<BookingJSON, PartnerBookingRelationshipsJSON>> {
  return api.get(`/partners/${partnerId}/bookings`);
}

export function getBooking(partnerId: PartnerJSON['id'], bookingId: BookingJSON['id']): Promise<BookingJSON> {
  return api.get(`/partners/${partnerId}/bookings/${bookingId}`);
}
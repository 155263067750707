import { HStack, Text } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';

interface Props {
  label: string;
  isValid: boolean;
}

export function Check({ label, isValid }: Props) {
  const icon = isValid ? 'squareRoundedCheck' : 'squareRoundedX';
  const color = isValid ? 'teal.400' : 'red.500';

  return (
    <HStack spacing={2}>
      <Icon size={5} icon={icon} color={color} />
      <Text>{label}</Text>
    </HStack>
  );
}
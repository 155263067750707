import { PartnerPayoutInformationJSON } from '@jurnee/common/src/entities/PartnerPayoutInformation';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PayoutInformationParams, createPayoutInformation } from 'api/payoutInformation';
import { RootState } from '..';

export const createPayoutInformationThunk = createAsyncThunk<PartnerPayoutInformationJSON, Omit<PayoutInformationParams
, 'payoutInformationId'>, { state: RootState }>('CREATE_PAYOUT_INFORMATION', async (params, thunkAPI) => {
  try {
    const payoutInformation = await createPayoutInformation(params);

    return payoutInformation;
  } catch (error) {
    return thunkAPI.rejectWithValue({ message: error.message });
  }
});
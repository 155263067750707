import { api } from '@jurnee/common/src/browser/Api';
import { PartnerDocumentUpsertBody } from '@jurnee/common/src/dtos/partnersDocuments';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { PartnerJSON } from '@jurnee/common/src/entities/Partner';
import { PartnerDocumentJSON } from '@jurnee/common/src/entities/PartnerDocument';
import { PartnerPaymentDocumentJSON } from '@jurnee/common/src/entities/PartnerPaymentDocument';
import { List } from '@jurnee/common/src/serializers';
import { FileStorageFile } from '@jurnee/common/src/services/FileStorageService';

interface PartnerDocumentParams {
  partnerId: PartnerJSON['id'];
  bookingId: BookingJSON['id'];
  partnerDocumentId: PartnerDocumentJSON['id'];
}

type PaymentDocumentParams = PartnerDocumentParams & {
  paymentDocumentId: PartnerPaymentDocumentJSON['id'];
}

export function getPartnerDocuments(partnerId: PartnerJSON['id']): Promise<List<PartnerDocumentJSON, never>> {
  return api.get(`/partners/${partnerId}/partners-documents`);
}

export function getPartnerDocumentsByBookingId(partnerId: PartnerJSON['id'], bookingId: BookingJSON['id']): Promise<List<PartnerDocumentJSON, never>> {
  return api.get(`/partners/${partnerId}/bookings/${bookingId}/partners-documents`);
}

export function uploadPartnerDocument(partnerId: PartnerJSON['id'], bookingId: BookingJSON['id'], file: File): Promise<FileStorageFile> {
  return api.uploadFile(`/partners/${partnerId}/bookings/${bookingId}/partners-documents/upload`, file);
}

export function createPartnerDocument(partnerId: PartnerJSON['id'], bookingId: BookingJSON['id'], body: PartnerDocumentUpsertBody): Promise<PartnerDocumentJSON> {
  return api.post(`/partners/${partnerId}/bookings/${bookingId}/partners-documents`, body);
}

export function deletePartnerDocument({ partnerId, bookingId, partnerDocumentId }: PartnerDocumentParams): Promise<PartnerDocumentJSON> {
  return api.delete(`/partners/${partnerId}/bookings/${bookingId}/partners-documents/${partnerDocumentId}`);
}

export function getPaymentDocumentUrl({ partnerId, bookingId, partnerDocumentId, paymentDocumentId }: PaymentDocumentParams): Promise<{ url: string }> {
  return api.get(`/partners/${partnerId}/bookings/${bookingId}/partners-documents/${partnerDocumentId}/payment-documents/${paymentDocumentId}/url`);
}

export function getPartnerDocumentUrl({ partnerId, bookingId, partnerDocumentId }: PartnerDocumentParams): Promise<{ url: string }> {
  return api.get(`/partners/${partnerId}/bookings/${bookingId}/partners-documents/${partnerDocumentId}/url`);
}
import { IconButton, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export function DotButton(props: Props) {
  return (
    <Menu placement="bottom-end">
      <MenuButton
        h={8}
        minW={8}
        borderRadius={4}
        as={IconButton}
        icon={<Icon icon="verticalDots" size={5} />}
        bg="gray.100"
        color="black"
        _hover={{ background: 'gray.200' }}
        _active={{ background: 'gray.300' }}
      />

      <MenuList>
        { props.children }
      </MenuList>
    </Menu>
  );
}
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import authStore from './auth/auth.store';
import bootstrapStore from './bootstrap/bootstrap.store';
import currenciesStore from './currencies/currencies.store';
import partnerStore from './partner/partner.store';
import partnerStatsStore from './partnerStats/partnerStats.store';
import partnerUsersStore from './partnerUsers/partnerUsers.store';
import userStore from './user/user.store';

export const store = configureStore({
  reducer: {
    auth: authStore,
    bootstrap: bootstrapStore,
    currencies: currenciesStore,
    partner: partnerStore,
    partnerStats: partnerStatsStore,
    partnerUsers: partnerUsersStore,
    user: userStore
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
{
  "createService": "Create service",
  "emptyState": {
    "description": "Create your first service to showcase your expertise and the different packages you offer",
    "heading": "You don't have any services yet"
  },
  "heading": "Services",
  "toasts": {
    "fetchExperiences": {
      "error": "An error occurred while retrieving your services"
    }
  }
}
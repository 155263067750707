import { Text, VStack } from '@chakra-ui/react';
import { PartnerDocumentJSON } from '@jurnee/common/src/entities/PartnerDocument';
import { PaymentStatus } from 'components/PaymentStatus';
import { useTranslation } from 'react-i18next';

interface Props {
  partnerDocuments: PartnerDocumentJSON[];
}

export function Payment({ partnerDocuments }: Props) {
  const { t } = useTranslation('booking');

  return (
    <VStack spacing={2} alignItems="flex-start">
      <Text fontWeight={700} lineHeight="16px" noOfLines={1}>{t('fields.payment')}</Text>
      <PaymentStatus partnerDocuments={partnerDocuments} />
    </VStack>
  );
}
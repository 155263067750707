import common from '@jurnee/common/src/locales/en/common.json';
import experience from '@jurnee/common/src/locales/en/experience.json';
import reviews from '@jurnee/common/src/locales/en/reviews.json';
import toasts from '@jurnee/common/src/locales/en/toasts.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import account from './en/account.json';
import billing from './en/billing.json';
import booking from './en/booking.json';
import bookings from './en/bookings.json';
import invoice from './en/invoice.json';
import navbar from './en/navbar.json';
import payoutInformation from './en/payoutInformation.json';
import products from './en/products.json';
import proposition from './en/proposition.json';
import request from './en/request.json';
import requests from './en/requests.json';
import service from './en/service.json';
import services from './en/services.json';

export const resources = {
  en: {
    account,
    billing,
    booking,
    bookings,
    common,
    experience,
    invoice,
    navbar,
    payoutInformation,
    products,
    proposition,
    request,
    requests,
    reviews,
    service,
    services,
    toasts
  }
};

i18n.use(initReactI18next).init({
  lng: 'en',
  ns: [
    'account',
    'billing',
    'booking',
    'bookings',
    'common',
    'experience',
    'invoice',
    'navbar',
    'payoutInformation',
    'products',
    'proposition',
    'request',
    'requests',
    'reviews',
    'service',
    'services',
    'toasts'
  ],
  resources
});
import { Td, Tr } from '@chakra-ui/react';
import { PrimaryButton } from '@jurnee/common/src/components/buttons/PrimaryButton';
import { EmptyState } from '@jurnee/common/src/components/EmptyState';
import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { ProductJSON } from '@jurnee/common/src/entities/Product';
import { ProductDrawer } from 'drawers/ProductDrawer';
import { useTranslation } from 'react-i18next';

interface Props {
  experienceId: ExperienceJSON['id'];
  onUpsert(product: ProductJSON): void;
}

export function EmptyListState({ experienceId, onUpsert }: Props) {
  const { t } = useTranslation('products');

  return (
    <Tr>
      <Td p={16} colSpan={4} border="none">
        <EmptyState
          icon="listSearch"
          heading={t('emptyState.heading')}
          description={t('emptyState.description')}
        >
          <ProductDrawer experienceId={experienceId} onUpsert={onUpsert}>
            <PrimaryButton size="sm" colorScheme="black">
              { t('createPackage') }
            </PrimaryButton>
          </ProductDrawer>
        </EmptyState>
      </Td>
    </Tr>
  );
}
import { Text, VStack } from '@chakra-ui/react';
import { PrimaryButton } from '@jurnee/common/src/components/buttons/PrimaryButton';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { PartnerDocumentJSON } from '@jurnee/common/src/entities/PartnerDocument';
import { PartnerInvoiceDrawer } from 'drawers/PartnerInvoiceDrawer';
import { useTranslation } from 'react-i18next';

interface Props {
  bookingId: BookingJSON['id'];
  onCreate(partnerDocument: PartnerDocumentJSON): void;
}

export function EmptyState(props: Props) {
  const { t } = useTranslation('booking');

  return (
    <VStack w="100%" spacing={5} px={5} py="60px" bg="white" border="1px solid" borderColor="gray.200" borderRadius={4}>
      <Text color="gray.400" whiteSpace="pre-line" textAlign="center">{t('invoices.emptyState.description')}</Text>

      <PartnerInvoiceDrawer bookingId={props.bookingId} onCreate={props.onCreate}>
        <PrimaryButton size="sm" colorScheme="black">
          { t('buttons.uploadInvoice') }
        </PrimaryButton>
      </PartnerInvoiceDrawer>
    </VStack>
  );
}
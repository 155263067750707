import { Tbody, Td, Tr } from '@chakra-ui/react';
import { EmptyState } from '@jurnee/common/src/components/EmptyState';
import { useTranslation } from 'react-i18next';
import { TabKey } from '../..';

interface Props {
  tab: TabKey;
}

export function EmptyListState({ tab }: Props) {
  const { t } = useTranslation('requests', { keyPrefix: 'tabs' });

  return (
    <Tbody>
      <Tr>
        <Td p={16} colSpan={6} border="none">
          <EmptyState
            icon="listSearch"
            heading={t(`${tab}.emptyState.heading`)}
            description={t(`${tab}.emptyState.description`)}
          />
        </Td>
      </Tr>
    </Tbody>
  );
}
import { Flex, FlexProps, IconButton, Spinner } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { useState } from 'react';

interface Props {
  url: string;
  isLoading: boolean;
  onDelete?(): void
}

export function ImageCard({ url, isLoading, onDelete, ...inheritedProps }: Props & FlexProps) {
  const [isDraggable, setIsDraggable] = useState(false);

  const color = isLoading ? 'rgba(255, 255, 255,  0.5)' : 'rgba(30, 0, 50, 0.15)';

  return (
    <Flex
      w="100%"
      flexDirection="column"
      aspectRatio={1}
      bgImage={`linear-gradient(0deg, ${color}, ${color}), url(${url});`}
      bgPosition="center"
      bgSize="cover"
      alignSelf="stretch"
      cursor={isLoading ? 'initial' : 'grab'}
      alignItems={isLoading ? 'center' : 'flex-end'}
      justifyContent={isLoading ? 'center' : 'initial'}
      borderRadius={4}
      p={2}
      role="group"
      draggable={isDraggable}
      onMouseDown={() => setIsDraggable(true)}
      onMouseLeave={() => setIsDraggable(false)}
      {...inheritedProps}
    >
      {
        isLoading ? (
          <Spinner color="white" />
        ) : (
          !isDraggable && !!onDelete &&
            <IconButton
              size="xs"
              aria-label="Delete photo"
              borderRadius={4}
              bg="white"
              _groupHover={{ opacity: 1 }}
              opacity={0}
              _hover={{ background: 'whiteAlpha.800' }}
              icon={<Icon icon="trash" size={4} color="black" />}
              onMouseDown={e => e.stopPropagation()}
              onClick={onDelete}
            />
        )
      }
    </Flex>
  );
}
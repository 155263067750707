import { api } from '@jurnee/common/src/browser/Api';
import { PropositionUpsertBody } from '@jurnee/common/src/dtos/propositions';
import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { PartnerJSON } from '@jurnee/common/src/entities/Partner';
import { PartnerRequestJSON, PartnerRequestRelationshipsJSON } from '@jurnee/common/src/entities/PartnerRequest';
import { PartnerRequestCommentJSON, PartnerRequestCommentRelationshipsJSON } from '@jurnee/common/src/entities/PartnerRequestComment';
import { PartnerRequestCommentAttachmentJSON } from '@jurnee/common/src/entities/PartnerRequestCommentAttachment';
import { PartnerPropositionRelationshipsJSON, PropositionJSON } from '@jurnee/common/src/entities/Proposition';
import { Expand, List } from '@jurnee/common/src/serializers';

export interface PartnerRequestParams {
  partnerId: PartnerJSON['id'];
  partnerRequestId: PartnerRequestJSON['id'];
}

export interface PartnerRequestDeclineParams extends PartnerRequestParams {
  data: { declinedReason: string };
}

interface PropositionCreateParams extends PartnerRequestParams {
  data: PropositionUpsertBody;
}

interface PropositionUpdateParams extends PartnerRequestParams {
  propositionId: PropositionJSON['id'];
  data: PropositionUpsertBody;
}

interface PropositionDeleteParams extends PartnerRequestParams {
  propositionId: PropositionJSON['id'];
}

export interface CommentCreateParams extends PartnerRequestParams {
  data: { content: string };
}

interface CommentParams extends PartnerRequestParams {
  commentId: PartnerRequestCommentJSON['id'];
}

interface CommentAttachmentParams extends CommentParams {
  attachmentId: PartnerRequestCommentAttachmentJSON['id'];
}

export function getPartnerRequest({ partnerId, partnerRequestId }: PartnerRequestParams): Promise<PartnerRequestJSON> {
  return api.get(`/partners/${partnerId}/partners-requests/${partnerRequestId}`);
}

export function getPartnerRequestPropositions({ partnerId, partnerRequestId }: PartnerRequestParams): Promise<List<PropositionJSON, PartnerPropositionRelationshipsJSON>> {
  return api.get(`/partners/${partnerId}/partners-requests/${partnerRequestId}/propositions`);
}

export function createPartnerRequestProposition({ partnerId, partnerRequestId, data }: PropositionCreateParams): Promise<Expand<PropositionJSON, PartnerPropositionRelationshipsJSON>> {
  return api.post(`/partners/${partnerId}/partners-requests/${partnerRequestId}/propositions`, data);
}

export function updatePartnerRequestProposition({ partnerId, partnerRequestId, propositionId, data }: PropositionUpdateParams): Promise<Expand<PropositionJSON, PartnerPropositionRelationshipsJSON>> {
  return api.put(`/partners/${partnerId}/partners-requests/${partnerRequestId}/propositions/${propositionId}`, data);
}

export function deletePartnerRequestProposition({ partnerId, partnerRequestId, propositionId }: PropositionDeleteParams): Promise<Expand<PropositionJSON, PartnerPropositionRelationshipsJSON>> {
  return api.delete(`/partners/${partnerId}/partners-requests/${partnerRequestId}/propositions/${propositionId}`);
}

export function getPartnerRequestComments({ partnerId, partnerRequestId }: PartnerRequestParams): Promise<List<PartnerRequestCommentJSON, PartnerRequestCommentRelationshipsJSON>> {
  return api.get(`/partners/${partnerId}/partners-requests/${partnerRequestId}/comments`);
}

export function createPartnerRequestComment({ partnerId, partnerRequestId, data }: CommentCreateParams): Promise<Expand<PartnerRequestCommentJSON, PartnerRequestCommentRelationshipsJSON>> {
  return api.post(`/partners/${partnerId}/partners-requests/${partnerRequestId}/comments`, data);
}

export function getPartnerRequestCommentAttachmentUrl({ partnerId, partnerRequestId, commentId, attachmentId }: CommentAttachmentParams): Promise<{ url: string }> {
  return api.get(`/partners/${partnerId}/partners-requests/${partnerRequestId}/comments/${commentId}/attachments/${attachmentId}/url`);
}

export function uploadPartnerRequestCommentAttachment({ partnerId, partnerRequestId, commentId }: CommentParams, file: File): Promise<PartnerRequestCommentAttachmentJSON> {
  return api.uploadFile(`/partners/${partnerId}/partners-requests/${partnerRequestId}/comments/${commentId}/attachments`, file);
}

export function declinePartnerRequest({ partnerId, partnerRequestId, data }: PartnerRequestDeclineParams): Promise<PartnerRequestJSON> {
  return api.put(`/partners/${partnerId}/partners-requests/${partnerRequestId}/decline`, data);
}

export function getPartnerRequests(partnerId: PartnerJSON['id'], params: URLSearchParams): Promise<List<PartnerRequestJSON, PartnerRequestRelationshipsJSON>> {
  return api.get(`/partners/${partnerId}/partners-requests?${params.toString()}`);
}

export function getExperiences(partnerId: PartnerJSON['id']): Promise<List<ExperienceJSON, never>> {
  return api.get(`/partners/${partnerId}/experiences`);
}
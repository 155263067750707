import { HStack, Heading, Text, VStack, useToast } from '@chakra-ui/react';
import { Loader } from '@jurnee/common/src/components/Loader';
import { PartnerPayoutInformationJSON } from '@jurnee/common/src/entities/PartnerPayoutInformation';
import { getErrorToast } from '@jurnee/common/src/utils/toasts';
import { getPayoutInformation } from 'api/payoutInformation';
import { PayoutInformationDrawer } from 'drawers/PayoutInformationDrawer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getPartnerSelector } from 'store/partner/partner.selectors';
import { EmptyState } from './EmptyState';
import { PayoutInformation } from './PayoutInformation';

export function PayoutInformationCard() {
  const toast = useToast();
  const { t } = useTranslation(['payoutInformation', 'common']);

  const partner = useSelector(getPartnerSelector);
  const [payoutInformation, setPayoutInformation] = useState<PartnerPayoutInformationJSON>(null);
  const [isPayoutInformationLoading, setIsPayoutInformationLoading] = useState(!!partner.partnersPayoutInformation);

  async function fetchPayoutInformation() {
    const payoutInformation = await getPayoutInformation(partner.id);
    setPayoutInformation(payoutInformation);
    setIsPayoutInformationLoading(false);
  }

  useEffect(() => {
    try {
      if (partner.partnersPayoutInformation && !payoutInformation) {
        fetchPayoutInformation();
      }
    } catch (err) {
      toast(getErrorToast(t('toasts.fetchPayoutInfo.error')));
    }
  }, []);

  function renderPayoutInformation() {
    if (isPayoutInformationLoading) {
      return <Loader
        w="100%"
        py="90px"
        bg="white"
        border="1px solid"
        borderColor="gray.200"
        borderRadius={4}
      />;
    }

    if (!payoutInformation) {
      return <EmptyState onUpsert={setPayoutInformation} />;
    }

    return <PayoutInformation payoutInformation={payoutInformation} />;
  }

  return (
    <VStack w="100%" spacing={2} alignItems="flex-start">
      <HStack w="100%" justifyContent="space-between">
        <Heading size="md">{t('drawer.title')}</Heading>

        {
          payoutInformation &&
            <PayoutInformationDrawer payoutInformation={payoutInformation} onUpsert={setPayoutInformation}>
              <Text variant="link">
                { t('common:buttons.edit') }
              </Text>
            </PayoutInformationDrawer>
        }
      </HStack>

      { renderPayoutInformation() }
    </VStack>
  );
}
{
  "comments": {
    "title": "Comments"
  },
  "declinedState": {
    "description": "Request for proposal has been declined",
    "heading": "Request declined"
  },
  "details": {
    "title": "Details"
  },
  "endedState": {
    "description": "An other provider's proposal has been selected or the request for proposal is no longer open",
    "heading": "Request for proposal ended"
  },
  "fields": {
    "city": "City",
    "eventDate": "Event date & time",
    "location": "Location",
    "participants": "Participants",
    "reference": "Reference",
    "status": "Status"
  },
  "heading": "Request",
  "modals": {
    "decline": {
      "message": "Are you sure you want to decline this request for proposal?\nBy declining, you will no longer be considered for this event.",
      "reason": {
        "label": "Reason for declining (optional):",
        "placeholder": "Please add any useful information"
      },
      "title": "Decline request for proposal"
    }
  },
  "propositions": {
    "createQuote": "Create a quote",
    "emptyState": {
      "description": "You didn't create any quotes for this request.\nIf you have any questions about it, please leave a comment below."
    },
    "title": "Quotes"
  },
  "status": {
    "CLOSED": "Closed",
    "DECLINED": "Declined",
    "PENDING_SELECTION": "Pending selection",
    "PROPOSAL_SENT": "Proposal sent",
    "REJECTED": "Rejected",
    "SELECTED": "Selected",
    "WAITING_PROPOSAL": "Waiting proposal"
  },
  "toasts": {
    "createComment": {
      "error": "An error occurred while submitting your comment",
      "success": "Your comment has been successfully added"
    },
    "declinePartnerRequest": {
      "error": "An error occurred while declining the request",
      "success": "The request has been declined"
    },
    "downloadFile": {
      "error": "An error occurred while downloading the attachment"
    },
    "fetchComments": {
      "error": "An error occurred while retrieving comments"
    },
    "fetchPartnerRequest": {
      "error": "An error occurred while retrieving the request"
    },
    "fetchPropositions": {
      "error": "An error occurred while retrieving your quotes"
    }
  }
}
import { PartnerDocumentJSON } from '@jurnee/common/src/entities/PartnerDocument';
import { getTotalAmount } from '@jurnee/common/src/utils/partnerDocuments';
import { formatPrice } from '@jurnee/common/src/utils/prices';
import { useTranslation } from 'react-i18next';
import { Field } from './Field';

interface Props {
  partnerDocuments: PartnerDocumentJSON[];
}

export function Price({ partnerDocuments }: Props) {
  const { t } = useTranslation('booking');

  const totalAmount = getTotalAmount(partnerDocuments);

  const price = partnerDocuments.length > 0 ?
    formatPrice(
      totalAmount,
      partnerDocuments[0].currencyId,
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
    ) : '-';

  return <Field label={t('fields.price')} value={price} />;
}
import { Divider, Flex, HStack, Image, MenuList } from '@chakra-ui/react';
import { MenuItemWrapper } from '@jurnee/common/src/components/MenuItemWrapper';
import '@jurnee/common/src/components/Navbar.css';
import { NavbarLink } from '@jurnee/common/src/components/NavbarLink';
import { UserCard } from '@jurnee/common/src/components/UserCard';
import { UserSettingsDrawer } from 'drawers/UserSettingsDrawer';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useAppDispatch } from 'store';
import { logout } from 'store/auth/auth.store';
import { getPartnerSelector } from 'store/partner/partner.selectors';
import { getPartnerStatsSelector } from 'store/partnerStats/partnerStats.selectors';
import { getUserSelector } from 'store/user/user.selectors';

export function Navbar() {
  const { t } = useTranslation('navbar');
  const user = useSelector(getUserSelector);
  const partner = useSelector(getPartnerSelector);
  const partnerStats = useSelector(getPartnerStatsSelector);
  const dispatch = useAppDispatch();

  function onLogout() {
    dispatch(logout());
  }

  return (
    <Flex flexDirection="column" minW="256px" h="100%" bg="white" px={5} pb={5} gap={4}>
      <NavLink to="/">
        <HStack className="jurnee-logo" justifyContent="flex-start" h="70px" w="100%" px={2}>
          <Image src="/assets/images/logo_full.svg" h="29px" />
        </HStack>
      </NavLink>

      <NavbarLink to="/bookings" icon="calendar" label={t('bookings')} />
      <NavbarLink to="/requests" icon="bellRing" label={t('requests')} count={partnerStats?.partnerRequests?.pending} />
      <NavbarLink to="/services" icon="tags" label={t('services')} />
      <NavbarLink to="/account" icon="storefront" label={t('account')} />
      <NavbarLink to="/billing" icon="receipt" label={t('billing')} />

      <Flex marginTop='auto' flexDirection='column' gap={5}>
        <Divider />

        <UserCard user={user} organization={partner}>
          <MenuList>
            <UserSettingsDrawer />
            <MenuItemWrapper
              icon='logout'
              label={t('userCard.logout')}
              onClick={onLogout}
            />
          </MenuList>
        </UserCard>
      </Flex>
    </Flex>
  );
}
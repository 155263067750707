import { HStack, Text, VStack, useToast } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { StatusBadge } from '@jurnee/common/src/components/StatusBadge';
import { SecondaryButton } from '@jurnee/common/src/components/buttons/SecondaryButton';
import { PartnerDocumentJSON } from '@jurnee/common/src/entities/PartnerDocument';
import { PropositionJSON } from '@jurnee/common/src/entities/Proposition';
import { PropositionsGroupJSON } from '@jurnee/common/src/entities/PropositionsGroup';
import { formatDate } from '@jurnee/common/src/utils/dates';
import { formatPrice } from '@jurnee/common/src/utils/prices';
import { isPropositionsGroupClosed } from '@jurnee/common/src/utils/propositionsGroups';
import { getErrorToast } from '@jurnee/common/src/utils/toasts';
import { getPartnerDocumentUrl } from 'api/partnerDocuments';
import { useTranslation } from 'react-i18next';

interface Props {
  propositionsGroup: PropositionsGroupJSON;
  proposition: PropositionJSON;
  quote: PartnerDocumentJSON;
}

export function QuoteLine({ propositionsGroup, proposition, quote }: Props) {
  const toast = useToast();
  const { t } = useTranslation('proposition', { keyPrefix: 'quote' });

  const amount = formatPrice(
    quote.amount,
    quote.currencyId,
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  );

  function getStatus() {
    if (proposition.selectedAt) {
      return 'SELECTED';
    }

    if (isPropositionsGroupClosed(propositionsGroup)) {
      return 'REJECTED';
    }

    return 'PENDING';
  }

  function getStatusProps() {
    const status = getStatus();

    switch(status) {
    case 'SELECTED': return { label: t('status.value.SELECTED'), color: 'teal' };
    case 'REJECTED': return { label: t('status.value.REJECTED'), color: 'red' };
    default: return { label: t('status.value.PENDING'), color: 'blue' };
    }
  }

  async function onDownload() {
    try {
      const { url } = await getPartnerDocumentUrl({
        partnerId: quote.partnerId,
        bookingId: quote.bookingId,
        partnerDocumentId: quote.id
      });

      window.open(url, '_blank');
    } catch(error) {
      toast(getErrorToast(t('toasts.download.error'), error.message));
    }
  }

  return (
    <HStack w="100%" justifyContent="space-between">
      <VStack w="30%" spacing={1} alignItems="flex-start">
        <Text fontWeight={700}>{t('amount')}</Text>

        <Text color="gray.400" lineHeight="16px">{amount}</Text>
      </VStack>

      <VStack w="30%" spacing={1} alignItems="flex-start">
        <Text fontWeight={700}>{t('status.label')}</Text>

        <StatusBadge {...getStatusProps()} />
      </VStack>

      <VStack w="30%" spacing={1} alignItems="flex-start">
        <Text fontWeight={700}>{t('expiration')}</Text>

        <Text color="gray.400" lineHeight="16px">
          { formatDate(new Date(proposition.expiredAt)) }
        </Text>
      </VStack>

      <SecondaryButton
        w="32px"
        h="32px"
        minW="32px"
        borderRadius={4}
        colorScheme="gray"
        onClick={onDownload}
      >
        <Icon icon="download" size={5} />
      </SecondaryButton>
    </HStack>
  );
}
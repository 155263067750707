import { HStack, Heading, SimpleGrid, VStack, useToast } from '@chakra-ui/react';
import { Loader } from '@jurnee/common/src/components/Loader';
import { PrimaryButton } from '@jurnee/common/src/components/buttons/PrimaryButton';
import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { getErrorToast } from '@jurnee/common/src/utils/toasts';
import { getExperiences } from 'api/experiences';
import { ExperienceCard } from 'components/ExperienceCard';
import { ExperienceDrawer } from 'drawers/ExperienceDrawer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPartnerSelector } from 'store/partner/partner.selectors';
import { EmptyStateCard } from './EmptyStateCard';

export function Services() {
  const toast = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation('services');

  const partner = useSelector(getPartnerSelector);

  const [experiences, setExperiences] = useState<ExperienceJSON[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  async function fetchExperiences() {
    setIsLoading(true);

    try {
      const { list } = await getExperiences(partner.id);
      setExperiences(list);
    } catch(error) {
      toast(getErrorToast(t('toasts.fetchExperiences.error')));
    }

    setIsLoading(false);
  }

  useEffect(() => {
    fetchExperiences();
  }, []);

  function onUpsert(experience: ExperienceJSON) {
    navigate(`/services/${experience.id}`);
  }

  return (
    <VStack w="100%" h="100%" spacing={0} minW={860}>
      <HStack w="100%" p={8} bg="white" borderBottom="1px solid" borderColor="gray.200" justifyContent="space-between">
        <Heading lineHeight="32px">{t('heading')}</Heading>

        {
          !isLoading && experiences.length > 0 &&
            <ExperienceDrawer onUpsert={onUpsert}>
              <PrimaryButton size="sm" colorScheme="black">
                { t('createService') }
              </PrimaryButton>
            </ExperienceDrawer>
        }
      </HStack>

      {
        isLoading ? (
          <Loader h={400} />
        ) : (
          experiences.length === 0 ? (
            <EmptyStateCard onUpsert={onUpsert} />
          ) : (
            <SimpleGrid w="100%" p={8} spacing={8} columns={{ sm: 2, md: 3, lg: 3, xl: 4, '2xl': 5 }}>
              {
                experiences.map(experience =>
                  <ExperienceCard
                    key={experience.id}
                    experience={experience}
                  />
                )
              }
            </SimpleGrid>
          )
        )
      }
    </VStack>
  );
}
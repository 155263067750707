import PartnerEntity, { PartnerUpdate } from '../entities/Partner';
import { isEmpty, sanitize } from './strings';

export function hasValidAccountInfo(partner: PartnerEntity) {
  if (isEmpty(partner.name)) {
    return false;
  }

  if (isEmpty(partner.description)) {
    return false;
  }

  if (isEmpty(partner.phoneNumber)) {
    return false;
  }

  if (isEmpty(partner.email)) {
    return false;
  }

  return !!partner.address;
}

export function cleanData<T extends PartnerUpdate>(data: T): T {
  if (typeof data.email === 'string') {
    data.email = data.email.trim().toLowerCase();
  }

  if (typeof data.description === 'string') {
    data.description = sanitize(data.description);
  }

  return data;
}
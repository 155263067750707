import { Box, HStack, Heading, Tab, TabList, TabPanels, Tabs, VStack, useToast } from '@chakra-ui/react';
import { BackButton } from '@jurnee/common/src/components/buttons/BackButton';
import { Loader } from '@jurnee/common/src/components/Loader';
import { ExperienceJSON, ExperienceRatingJSON } from '@jurnee/common/src/entities/Experience';
import { getDefaultTabIndex } from '@jurnee/common/src/utils/tabs';
import { getErrorToast } from '@jurnee/common/src/utils/toasts';
import { getExperience } from 'api/experiences';
import { ExperienceStatus } from 'components/ExperienceStatus';
import { PublishExperienceModal } from 'modals/PublishExperienceModal';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, Params, useParams, useSearchParams } from 'react-router-dom';
import { getPartnerSelector } from 'store/partner/partner.selectors';
import { router } from '../../router';
import { Description } from './Description';
import { DotMenu } from './DotMenu';
import { Packages } from './Packages';
import { Photos } from './Photos';
import { Preview } from './Preview';

export type TabKey = 'description' | 'photos' | 'packages' | 'preview';

export function Service() {
  const toast = useToast();
  const params = useParams<Params<'experienceId'>>();
  const experienceId = Number(params.experienceId);
  const [, setSearchParams] = useSearchParams();
  const { t } = useTranslation('service');

  const partner = useSelector(getPartnerSelector);
  const [experience, setExperience] = useState<ExperienceJSON>(null);
  const [rating, setRating] = useState<ExperienceRatingJSON>(null);
  const [isLoading, setIsLoading] = useState(true);

  async function fetchExperience() {
    try {
      const { data, relationships: { experiencesRatings } } = await getExperience({ partnerId: partner.id, experienceId });
      setExperience(data);
      setRating(experiencesRatings[0]);
      setIsLoading(false);
    } catch(err) {
      toast(getErrorToast(t('toasts.fetchExperience.error')));
    }
  }

  useEffect(() => {
    fetchExperience();
  }, [experienceId]);

  if (isLoading) {
    return <Loader />;
  }

  const tabs: { label: string, key: TabKey }[] = [{
    label: `${t('tabs.description.label')}`,
    key: 'description'
  }, {
    label: `${t('tabs.photos.label')}`,
    key: 'photos'
  }, {
    label: `${t('tabs.packages.label')}`,
    key: 'packages'
  }, {
    label: `${t('tabs.preview.label')}`,
    key: 'preview'
  }];

  return (
    <Tabs
      defaultIndex={getDefaultTabIndex(tabs, router.state.location.search)}
      onChange={index => setSearchParams({ tab: tabs[index].key })}
      isLazy
    >
      <VStack w="100%" h="100%" spacing={0}>
        <Box w="100%" bg="white">
          <HStack w="100%" p={8} justifyContent="space-between">
            <HStack spacing={4}>
              <Link to="/services">
                <BackButton />
              </Link>

              <Heading lineHeight="32px">{experience.name}</Heading>

              <ExperienceStatus status={experience.status} />
            </HStack>

            <HStack spacing={5}>
              {
                experience.status === 'UNAVAILABLE' &&
                  <PublishExperienceModal experience={experience} onPublish={setExperience} />
              }

              <DotMenu experience={experience} onUpdate={setExperience} />
            </HStack>
          </HStack>

          <TabList>
            { tabs.map(tab => (<Tab key={tab.key}>{tab.label}</Tab>)) }
          </TabList>
        </Box>

        <TabPanels w="100%" display="flex" flexDirection="column" alignItems="center" maxW="initial">
          <Description experience={experience} onChange={setExperience} />
          <Photos experience={experience} onChange={setExperience} />
          <Packages experience={experience} onChange={setExperience} />
          <Preview experience={experience} rating={rating} />
        </TabPanels>
      </VStack>
    </Tabs>
  );
}
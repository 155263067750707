import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormHelperText, FormLabel, HStack, Input, VStack, useDisclosure, useToast } from '@chakra-ui/react';
import { PrimaryButton } from '@jurnee/common/src/components/buttons/PrimaryButton';
import { SecondaryButton } from '@jurnee/common/src/components/buttons/SecondaryButton';
import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { createExperience, updateExperience } from 'api/experiences';
import { cloneElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getPartnerSelector } from 'store/partner/partner.selectors';
import { TypeSelector } from './TypeSelector';

interface Props {
  experience?: ExperienceJSON;
  children: React.ReactElement;
  onUpsert(experience: ExperienceJSON): void;
}

const NAME_MAX_LENGTH = 40;

export function ExperienceDrawer(props: Props) {
  const toast = useToast();
  const { t } = useTranslation(['service', 'common']);

  const partner = useSelector(getPartnerSelector);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSaving, setIsSaving] = useState(false);

  const [name, setName] = useState<ExperienceJSON['name']>(props.experience?.name ?? '');
  const [type, setType] = useState<ExperienceJSON['type']>(props.experience?.type ?? null);

  const isNameInvalid = !name || isEmpty(name) || name.length > NAME_MAX_LENGTH;
  const isTypeInvalid = !type || isEmpty(type);

  const isSaveDisabled = isNameInvalid || isTypeInvalid;

  async function create() {
    try {
      const experience = await createExperience({
        partnerId: partner.id,
        body: { name, type }
      });

      onClose();
      props.onUpsert(experience);
      toast(getSuccessToast(t('drawers.create.toasts.success')));
    } catch(err) {
      toast(getErrorToast(t('drawers.create.toasts.error')));
    }
  }

  async function update() {
    try {
      const experience = await updateExperience({
        partnerId: partner.id,
        experienceId: props.experience.id,
        body: { name, type }
      });

      onClose();
      props.onUpsert(experience);
      toast(getSuccessToast(t('drawers.edit.toasts.success')));
    } catch(err) {
      toast(getErrorToast(t('drawers.edit.toasts.error')));
    }
  }

  async function onSave() {
    setIsSaving(true);

    props.experience ? await update() : await create();

    setIsSaving(false);
  }

  return (
    <>
      { cloneElement(props.children, { onClick: onOpen }) }

      <Drawer isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            { t(`drawers.${props.experience ? 'edit' : 'create'}.title`) }
            <DrawerCloseButton />
          </DrawerHeader>

          <DrawerBody p={5}>
            <VStack spacing={5}>
              <FormControl isRequired>
                <HStack w="100%" justifyContent="space-between">
                  <FormLabel>{t(`common:fields.name.label`)}</FormLabel>

                  <FormHelperText
                    mb="4px"
                    color={name.length > NAME_MAX_LENGTH ? 'red.500' : 'gray.400'}
                  >
                    { t('drawers.form.name.maxLength', { count: name.length, max: NAME_MAX_LENGTH }) }
                  </FormHelperText>
                </HStack>

                <Input
                  size="sm"
                  name="name"
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </FormControl>

              <TypeSelector value={type} onChange={setType} />
            </VStack>
          </DrawerBody>

          <DrawerFooter>
            <HStack justifyContent="space-between" w="100%">
              <SecondaryButton size="sm" colorScheme="pink" onClick={onClose}>
                { t('common:buttons.close') }
              </SecondaryButton>

              <PrimaryButton size="sm" colorScheme="teal" onClick={onSave} isLoading={isSaving} isDisabled={isSaveDisabled}>
                { t('common:buttons.save') }
              </PrimaryButton>
            </HStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
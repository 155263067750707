{
  "heading": "Requests",
  "tabs": {
    "closed": {
      "emptyState": {
        "description": "You don't have any closed requests at the moment, but the next ones will appear here",
        "heading": "No closed requests"
      },
      "label": "Closed"
    },
    "pending": {
      "emptyState": {
        "description": "It looks like you haven't received any requests for proposals yet",
        "heading": "No requests pending"
      },
      "label": "Pending"
    }
  },
  "toasts": {
    "fetchPartnerRequests": {
      "error": "An error occurred while retrieving your requests"
    }
  }
}
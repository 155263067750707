import { HStack, Heading, Text, VStack, useToast } from '@chakra-ui/react';
import { Loader } from '@jurnee/common/src/components/Loader';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { PartnerDocumentJSON } from '@jurnee/common/src/entities/PartnerDocument';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { deletePartnerDocument, getPartnerDocumentsByBookingId } from 'api/partnerDocuments';
import { PartnerInvoiceDrawer } from 'drawers/PartnerInvoiceDrawer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getPartnerSelector } from 'store/partner/partner.selectors';
import { InvoicesList } from './InvoicesList';

interface Props {
  bookingId: BookingJSON['id'];
}

export function Invoices(props: Props) {
  const toast = useToast();
  const { t } = useTranslation(['booking', 'invoice']);

  const partner = useSelector(getPartnerSelector);

  const [partnerDocuments, setPartnerDocuments] = useState<PartnerDocumentJSON[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  async function fetchPartnerDocuments() {
    try {
      const { list } = await getPartnerDocumentsByBookingId(partner.id, props.bookingId);
      setPartnerDocuments(list);
    } catch(error) {
      toast(getErrorToast(t('toasts.fetchPartnerDocuments.error')));
    }

    setIsLoading(false);
  }

  useEffect(() => {
    try {
      fetchPartnerDocuments();
    } catch (err) {
      toast(getErrorToast(t('toasts.fetchBooking.error')));
    }
  }, []);

  function onCreate(partnerDocument: PartnerDocumentJSON) {
    setPartnerDocuments([...partnerDocuments, partnerDocument]);
  }

  async function onDelete(partnerDocument: PartnerDocumentJSON) {
    try {
      const deletedPartnerDocument = await deletePartnerDocument({
        partnerId: partnerDocument.partnerId,
        bookingId: partnerDocument.bookingId,
        partnerDocumentId: partnerDocument.id
      });

      setPartnerDocuments(partnerDocuments.filter(({ id }) => id !== deletedPartnerDocument.id));
      toast(getSuccessToast(t('invoice:dotMenu.delete.toasts.success')));
    } catch(error) {
      toast(getErrorToast(t('invoice:dotMenu.delete.toasts.error'), error.message));
    }
  }

  return (
    <VStack w="100%" spacing={2} alignItems="flex-start">
      <HStack w="100%" justifyContent="space-between">
        <Heading size="md">{t('invoices.title')}</Heading>

        {
          partnerDocuments.length > 0 &&
            <PartnerInvoiceDrawer bookingId={props.bookingId} onCreate={onCreate}>
              <Text variant="link">
                { t('buttons.uploadInvoice') }
              </Text>
            </PartnerInvoiceDrawer>
        }
      </HStack>

      {
        isLoading ?
          <Loader
            w="100%"
            px={5}
            py="60px"
            bg="white"
            border="1px solid"
            borderColor="gray.200"
            borderRadius={4}
          /> :
          <InvoicesList
            bookingId={props.bookingId}
            partnerDocuments={partnerDocuments}
            onCreate={onCreate}
            onDelete={onDelete}
          />
      }
    </VStack>
  );
}
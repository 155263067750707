import { Text, VStack } from '@chakra-ui/react';
import '@jurnee/common/src/components/Navbar.css';
import { PartnerPayoutInformationJSON } from '@jurnee/common/src/entities/PartnerPayoutInformation';
import { formatAddress } from '@jurnee/common/src/utils/addresses';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import { useTranslation } from 'react-i18next';

interface Props {
  payoutInformation: PartnerPayoutInformationJSON;
}

export function PayoutInformation({ payoutInformation }: Props) {
  const { t } = useTranslation('common', { keyPrefix: 'fields' });

  return (
    <VStack w="100%" spacing={3} p={4} bg="white" border="1px solid" borderColor="gray.200" borderRadius={4}>
      <VStack w="100%" spacing={0} alignItems="flex-start">
        <Text fontWeight={700}>{t('legalCompanyName.label')}</Text>
        <Text color="gray.400">{payoutInformation.companyName}</Text>
      </VStack>

      <VStack w="100%" spacing={0} alignItems="flex-start">
        <Text fontWeight={700}>{t('billingAddress.label')}</Text>
        <Text color="gray.400" whiteSpace="pre-line">{formatAddress(payoutInformation.address)}</Text>
      </VStack>

      {
        !isEmpty(payoutInformation.vatNumber) &&
          <VStack w="100%" spacing={0} alignItems="flex-start">
            <Text fontWeight={700}>{t('vatNumber.label')}</Text>
            <Text color="gray.400">{payoutInformation.vatNumber}</Text>
          </VStack>
      }

      {
        !isEmpty(payoutInformation.registrationNumber) &&
          <VStack w="100%" spacing={0} alignItems="flex-start">
            <Text fontWeight={700}>{t('registrationNumber.label')}</Text>
            <Text color="gray.400">{payoutInformation.registrationNumber}</Text>
          </VStack>
      }

      {
        !isEmpty(payoutInformation.iban) &&
          <VStack w="100%" spacing={0} alignItems="flex-start">
            <Text fontWeight={700}>{t('iban.label')}</Text>
            <Text color="gray.400">{payoutInformation.iban}</Text>
          </VStack>
      }

      {
        !isEmpty(payoutInformation.accountNumber) &&
          <VStack w="100%" spacing={0} alignItems="flex-start">
            <Text fontWeight={700}>{t('accountNumber.label')}</Text>
            <Text color="gray.400">{payoutInformation.accountNumber}</Text>
          </VStack>
      }

      <VStack w="100%" spacing={0} alignItems="flex-start">
        <Text fontWeight={700}>{t('bicSwift.label')}</Text>
        <Text color="gray.400">{payoutInformation.bicSwift}</Text>
      </VStack>

      {
        !isEmpty(payoutInformation.bankCode) &&
          <VStack w="100%" spacing={0} alignItems="flex-start">
            <Text fontWeight={700}>{t('bankCode.label')}</Text>
            <Text color="gray.400">{payoutInformation.bankCode}</Text>
          </VStack>
      }

      {
        !isEmpty(payoutInformation.routingNumber) &&
          <VStack w="100%" spacing={0} alignItems="flex-start">
            <Text fontWeight={700}>{t('routingNumber.label')}</Text>
            <Text color="gray.400">{payoutInformation.routingNumber}</Text>
          </VStack>
      }
    </VStack>
  );
}
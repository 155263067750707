import { HStack, LinkBox, LinkOverlay, Text, VStack } from '@chakra-ui/react';
import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { getExperienceMaxParticipants } from '@jurnee/common/src/utils/experiences';
import { useTranslation } from 'react-i18next';
import { ExperienceStatus } from '../ExperienceStatus';
import { ImagesGrid } from './ImageGrid';

interface Props {
  experience: ExperienceJSON;
}

export function ExperienceCard({ experience }: Props) {
  const { t } = useTranslation('service');

  const participants = experience.products.length > 0 ? ` · ${t('participants', { count: getExperienceMaxParticipants(experience) })}` : null;

  return (
    <LinkBox>
      <VStack w="100%" spacing={4}>
        <ImagesGrid experiencesImages={experience.experiencesImages} />

        <VStack w="100%" spacing={0}>
          <HStack w="100%" justifyContent="space-between">
            <Text fontWeight={700} noOfLines={1}>{experience.name}</Text>
            <ExperienceStatus status={experience.status} />
          </HStack>

          <LinkOverlay w="100%" href={`/services/${experience.id}`}>
            <Text w="100%" color="gray.400">{t(`type.${experience.type}`)}{participants}</Text>
          </LinkOverlay>
        </VStack>
      </VStack>
    </LinkBox>
  );
}
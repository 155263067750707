import { api } from '@jurnee/common/src/browser/Api';
import { ExperienceCreateBody, ExperienceUpdateBody } from '@jurnee/common/src/dtos/experiences';
import { ExperienceImagesBulkUpdateBody } from '@jurnee/common/src/dtos/experiencesImages';
import { PricingCreateBody, PricingUpdateBody, ProductUpsertBody } from '@jurnee/common/src/dtos/products';
import { ExperienceJSON, ExperienceRelationshipsJSON, ExperienceStats } from '@jurnee/common/src/entities/Experience';
import { ExperienceImageJSON } from '@jurnee/common/src/entities/ExperienceImage';
import { PartnerJSON } from '@jurnee/common/src/entities/Partner';
import { ProductJSON } from '@jurnee/common/src/entities/Product';
import { Expand, List } from '@jurnee/common/src/serializers';

export interface ExperienceParams {
  partnerId: PartnerJSON['id'];
  experienceId: ExperienceJSON['id'];
}

type ExperienceCreateParams = Pick<ExperienceParams, 'partnerId'> & {
  body: ExperienceCreateBody;
}

type ExperienceUpdateParams = ExperienceParams & {
  body: ExperienceUpdateBody;
}

type ExperienceImageParams = ExperienceParams & {
  experienceImageId: ExperienceImageJSON['id'];
}

type ExperienceImagesBulkUpdateParams = ExperienceParams & {
  body: ExperienceImagesBulkUpdateBody;
}

type ProductParams = ExperienceParams & {
  productId: ProductJSON['id'];
}

export function getExperiences(partnerId: PartnerJSON['id']): Promise<List<ExperienceJSON, never>> {
  return api.get(`/partners/${partnerId}/experiences`);
}

export function getExperience({ partnerId, experienceId }: ExperienceParams): Promise<Expand<ExperienceJSON, Pick<ExperienceRelationshipsJSON, 'experiencesRatings'>>> {
  return api.get(`/partners/${partnerId}/experiences/${experienceId}`);
}

export function createExperience({ partnerId , body }: ExperienceCreateParams): Promise<ExperienceJSON> {
  return api.post(`/partners/${partnerId}/experiences`, body);
}

export function updateExperience({ partnerId, experienceId, body }: ExperienceUpdateParams): Promise<ExperienceJSON> {
  return api.put(`/partners/${partnerId}/experiences/${experienceId}`, body);
}

export function publishExperience({ partnerId, experienceId }: ExperienceParams): Promise<ExperienceJSON> {
  return api.put(`/partners/${partnerId}/experiences/${experienceId}/publish`);
}

export function uploadExperienceImage({ partnerId, experienceId }: ExperienceParams, file: File): Promise<ExperienceImageJSON> {
  return api.uploadFile(`/partners/${partnerId}/experiences/${experienceId}/images`, file);
}

export function deleteExperienceImage({ partnerId, experienceId, experienceImageId }: ExperienceImageParams): Promise<ExperienceImageJSON> {
  return api.delete(`/partners/${partnerId}/experiences/${experienceId}/images/${experienceImageId}`);
}

export function updateExperienceImages({ partnerId, experienceId, body }: ExperienceImagesBulkUpdateParams): Promise<List<ExperienceImageJSON, never>> {
  return api.put(`/partners/${partnerId}/experiences/${experienceId}/images`, body);
}

export function getExperienceStats(id: ExperienceJSON['id']): Promise<ExperienceStats> {
  return api.get(`experiences/${id}/stats`);
}

export function createProduct({ partnerId, experienceId, body }: ExperienceParams & { body: ProductUpsertBody<PricingCreateBody> }): Promise<ProductJSON> {
  return api.post(`/partners/${partnerId}/experiences/${experienceId}/products`, body);
}

export function updateProduct({ partnerId, experienceId, productId, body }: ProductParams & { body: ProductUpsertBody<PricingUpdateBody> }): Promise<ProductJSON> {
  return api.put(`/partners/${partnerId}/experiences/${experienceId}/products/${productId}`, body);
}

export function deleteProduct({ partnerId, experienceId, productId }: ProductParams): Promise<ProductJSON> {
  return api.delete(`/partners/${partnerId}/experiences/${experienceId}/products/${productId}`);
}
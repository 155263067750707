import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormLabel, HStack, Input, Textarea, VStack, useToast } from '@chakra-ui/react';
import AmountInput, { AmountInputData } from '@jurnee/common/src/components/AmountInput';
import { DatePicker } from '@jurnee/common/src/components/DatePicker';
import { FileInput } from '@jurnee/common/src/components/FileInput';
import { Loader } from '@jurnee/common/src/components/Loader';
import { PrimaryButton } from '@jurnee/common/src/components/buttons/PrimaryButton';
import { SecondaryButton } from '@jurnee/common/src/components/buttons/SecondaryButton';
import { DOCUMENT_CONTENT_TYPES } from '@jurnee/common/src/constants/ContentTypes';
import { PartnerDocumentUpsertBody } from '@jurnee/common/src/dtos/partnersDocuments';
import { DEFAULT_UTC_TIMEZONE } from '@jurnee/common/src/entities/Address';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { PartnerDocumentJSON } from '@jurnee/common/src/entities/PartnerDocument';
import { PartnerRequestJSON } from '@jurnee/common/src/entities/PartnerRequest';
import { PropositionJSON } from '@jurnee/common/src/entities/Proposition';
import { convertToTimezone, getCurrentTimeZone } from '@jurnee/common/src/utils/dates';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { createPartnerDocument, uploadPartnerDocument } from 'api/partnerDocuments';
import { createPartnerRequestProposition, updatePartnerRequestProposition } from 'api/partnersRequests';
import { cloneElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { getCurrenciesFetchStatusSelector, getCurrenciesKeys } from 'store/currencies/currencies.selector';
import { getCurrenciesThunk } from 'store/currencies/currencies.thunks';
import { getPartnerSelector } from 'store/partner/partner.selectors';

export interface QuoteDrawerData {
  proposition: PropositionJSON;
  quote: PartnerDocumentJSON;
}

interface Props {
  bookingId: BookingJSON['id'];
  partnerRequestId: PartnerRequestJSON['id'];
  proposition?: PropositionJSON;
  quote?: PartnerDocumentJSON;
  children: React.ReactElement;
  onUpsert(data: QuoteDrawerData): void;
}

export function PartnerQuoteDrawer(props: Props) {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { t } = useTranslation(['proposition', 'common']);

  const partner = useSelector(getPartnerSelector);
  const currencies = useSelector(getCurrenciesKeys);
  const currenciesFetchStatus = useSelector(getCurrenciesFetchStatusSelector);

  const [isOpen, setIsOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [amount, setAmount] = useState(props.quote?.amount || null);
  const [currencyId, setCurrencyId] = useState(props.quote?.currencyId || 'EUR');
  const [expiredAt, setExpiredAt] = useState(props.proposition?.expiredAt || null);
  const [name, setName] = useState(props.proposition?.name || null);
  const [comment, setComment] = useState(props.proposition?.comment || null);

  const [file, setFile] = useState<File>(null);
  const [hasFile, setHasFile] = useState(!!props.quote?.partnerId);

  const isAmountInvalid = amount !== null && amount <= 0;
  const isNameInvalid = !name || isEmpty(name);

  const isSaveDisabled = !amount || isAmountInvalid || !hasFile || isNameInvalid || !expiredAt;
  const isLoading = currenciesFetchStatus !== 'FETCHED';

  const quoteBody: Omit<PartnerDocumentUpsertBody, 'path'> = {
    amount,
    currencyId,
    dueAt: null,
    documentReference: null,
    type: 'QUOTE',
    filename: file?.name,
    size: file?.size
  };

  function onOpen() {
    setIsOpen(true);

    if (currenciesFetchStatus !== 'FETCHED') {
      dispatch(getCurrenciesThunk());
    }
  }

  function onClose() {
    setIsOpen(false);
  }

  async function uploadQuote() {
    const { path } = await uploadPartnerDocument(partner.id, props.bookingId, file);
    return createPartnerDocument(partner.id, props.bookingId, { ...quoteBody, path });
  }

  async function create() {
    const quote = await uploadQuote();
    const expiredAtUTC = convertToTimezone(expiredAt, getCurrentTimeZone(), DEFAULT_UTC_TIMEZONE);

    const { data, relationships: { partnersDocuments } } = await createPartnerRequestProposition({
      partnerId: partner.id,
      partnerRequestId: props.partnerRequestId,
      data: {
        name,
        comment,
        expiredAt: expiredAtUTC,
        partnerDocument: {
          id: quote.id
        }
      }
    });

    return { proposition: data, quote: partnersDocuments[0] };
  }

  async function update() {
    const quote = file ? await uploadQuote() : props.quote;
    const expiredAtUTC = convertToTimezone(expiredAt, getCurrentTimeZone(), DEFAULT_UTC_TIMEZONE);

    const { data, relationships: { partnersDocuments } } = await updatePartnerRequestProposition({
      partnerId: partner.id,
      partnerRequestId: props.partnerRequestId,
      propositionId: props.proposition.id,
      data: {
        name,
        comment,
        expiredAt: expiredAtUTC,
        partnerDocument: {
          id: quote.id,
          amount,
          currencyId
        }
      }
    });

    return { proposition: data, quote: partnersDocuments[0] };
  }

  async function onSave() {
    setIsSaving(true);

    try {
      const data = await (props.proposition ? update() : create());
      props.onUpsert(data);
      onClose();
      toast(getSuccessToast(t('drawer.form.toasts.success')));
    } catch(err) {
      toast(getErrorToast(t('drawer.form.toasts.error')));
    }

    setIsSaving(false);
  }

  function onAmountChange({ amount, currency }: AmountInputData) {
    setAmount(amount);
    setCurrencyId(currency);
  }

  function onDateChange(date: Date) {
    if (!date) {
      return;
    }

    setExpiredAt(date.toISOString());
  }

  function onFileChange(file: File) {
    setFile(file);
    setHasFile(true);
  }

  function onFileRemoved() {
    setFile(null);
    setHasFile(false);
  }

  return (
    <>
      { cloneElement(props.children, { onClick: onOpen }) }

      <Drawer isOpen={isOpen} onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            { t(`drawer.title.${props.proposition ? 'edit' : 'create'}`) }
            <DrawerCloseButton />
          </DrawerHeader>

          <DrawerBody p={5}>
            {
              isLoading ? (
                <Loader />
              ) : (
                <VStack spacing={5}>
                  <FormControl isRequired>
                    <FormLabel>{t('common:fields.name.label')}</FormLabel>

                    <Input
                      size="sm"
                      name="name"
                      value={name || ''}
                      placeholder={t(`drawer.form.name.placeholder`)}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>{t('common:fields.comment.label')}</FormLabel>

                    <Textarea
                      size="sm"
                      h="200px"
                      resize="none"
                      value={comment || ''}
                      placeholder={t('drawer.form.comment.placeholder')}
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </FormControl>

                  <HStack w="100%" spacing={5}>
                    <FormControl w="50%" id="amount" isRequired>
                      <FormLabel>{t('drawer.form.amount.label')}</FormLabel>

                      <AmountInput
                        defaultAmount={amount}
                        defaultCurrency={currencyId}
                        placeholder={t('drawer.form.amount.placeholder')}
                        currencies={currencies}
                        onChange={onAmountChange}
                        isInvalid={isAmountInvalid}
                      />
                    </FormControl>

                    <FormControl w="50%" id="expiredAt" isRequired={true}>
                      <FormLabel>{t('drawer.form.expiredAt.label')}</FormLabel>

                      <DatePicker
                        popperPlacement="top"
                        dateFormat="dd MMM yyyy"
                        selected={expiredAt ? new Date(expiredAt) : null}
                        placeholderText={t('drawer.form.expiredAt.placeholder')}
                        onChange={onDateChange}
                        inputProps={{ size: 'sm' }}
                      />
                    </FormControl>
                  </HStack>

                  <FormControl id="quote" isRequired>
                    <FormLabel>{t('drawer.form.document.label')}</FormLabel>

                    <FileInput
                      label={t('common:fields.fileDocument.label')}
                      sublabel={t('common:fields.fileDocument.documentTypes')}
                      defaultIdentifier={props.quote?.id}
                      defaultName={props.quote?.filename}
                      defaultSize={props.quote?.size}
                      isSavedFile={!!props.quote}
                      contentTypes={DOCUMENT_CONTENT_TYPES}
                      imageMaxWidth={1200}
                      onChange={onFileChange}
                      onRemove={onFileRemoved}
                    />
                  </FormControl>
                </VStack>
              )
            }
          </DrawerBody>

          <DrawerFooter>
            <HStack justifyContent="space-between" w="100%">
              <SecondaryButton size="sm" colorScheme="pink" onClick={onClose}>
                { t('common:buttons.close') }
              </SecondaryButton>

              <PrimaryButton size="sm" colorScheme="teal" onClick={onSave} isLoading={isSaving} isDisabled={isSaveDisabled}>
                { t('common:buttons.save') }
              </PrimaryButton>
            </HStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
import { StatusBadge } from '@jurnee/common/src/components/StatusBadge';
import { PartnerDocumentJSON } from '@jurnee/common/src/entities/PartnerDocument';
import { PaymentStatus, getPaidPercentage, getPaymentStatus } from '@jurnee/common/src/utils/partnerDocuments';
import { Trans } from 'react-i18next';

interface Props {
  partnerDocuments: PartnerDocumentJSON[];
}

function getColor(status: PaymentStatus) {
  if (status === 'PAID_FULLY') {
    return 'teal';
  }

  if (status === 'PAID_PARTIALLY') {
    return 'blue';
  }

  return 'gray';
}

export function PaymentStatus({ partnerDocuments }: Props) {
  const status = getPaymentStatus(partnerDocuments);

  const color = getColor(status);
  const percentage = getPaidPercentage(partnerDocuments);
  const label = <Trans i18nKey={`invoice:status.${status}`} values={{ percentage }} />;

  return <StatusBadge label={label} color={color} />;
}
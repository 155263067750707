import { api } from '@jurnee/common/src/browser/Api';
import { PartnerStatsJSON } from '@jurnee/common/src/dtos/partners';
import { AddressCreate, AddressJSON, AddressUpdate } from '@jurnee/common/src/entities/Address';
import { PartnerJSON, PartnerUpdate } from '@jurnee/common/src/entities/Partner';
import { PartnerProviderInformationCreate, PartnerProviderInformationJSON } from '@jurnee/common/src/entities/PartnerProviderInformation';

export interface PartnerUpdatePayload {
  id: PartnerJSON['id'];
  data: PartnerUpdate;
}

export interface PartnerAddressCreatePayload {
  id: PartnerJSON['id'];
  data: Omit<AddressCreate, 'countryCode'>;
}

export interface PartnerProviderInformationCreatePayload {
  id: PartnerJSON['id'];
  data: PartnerProviderInformationCreate;
}

export interface PartnerAddressUpdatePayload {
  id: PartnerJSON['id'];
  addressId: AddressJSON['id'];
  data: AddressUpdate;
}

export function getPartner(id: PartnerJSON['id']): Promise<PartnerJSON> {
  return api.get(`/partners/${id}`);
}

export function updatePartner({ id, data }: PartnerUpdatePayload): Promise<PartnerJSON> {
  return api.put(`/partners/${id}`, data);
}

export function createPartnerProviderInformation({ id, data }: PartnerProviderInformationCreatePayload): Promise<PartnerProviderInformationJSON> {
  return api.post(`/partners/${id}/providers-information`, data);
}

export function createPartnerAddress({ id, data }: PartnerAddressCreatePayload): Promise<AddressJSON> {
  return api.post(`/partners/${id}/address`, data);
}

export function updatePartnerAddress({ id, addressId, data }: PartnerAddressUpdatePayload): Promise<AddressJSON> {
  return api.put(`/partners/${id}/address/${addressId}`, data);
}

export function getPartnerStats(id: PartnerJSON['id']): Promise<PartnerStatsJSON> {
  return api.get(`/partners/${id}/stats`);
}
import { logger } from '@jurnee/common/src/browser/Logger';
import { PartnerJSON } from '@jurnee/common/src/entities/Partner';
import { PartnerUserJSON } from '@jurnee/common/src/entities/PartnerUser';
import { PartnerJwt, parseJwt } from '@jurnee/common/src/utils/auth';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPartner } from 'api/partner';
import { getUser } from 'api/user';
import { RootState } from 'store';
import { logout } from 'store/auth/auth.store';
import { getPartnerStatsThunk } from 'store/partnerStats/partnerStats.thunks';
// import { showToast } from '../toasts/toasts.thunks';

interface BootstrapPayload {
  user: PartnerUserJSON;
  partner: PartnerJSON;
}

export const bootstrap = createAsyncThunk<BootstrapPayload, void, { state: RootState }>('BOOTSTRAP', async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState();

    const { partnerUserId, partnerId } = parseJwt<PartnerJwt>(state.auth.token);

    const [partner, user] = await Promise.all([
      state.partner.data ? Promise.resolve(state.partner.data) : getPartner(partnerId),
      state.user.data ? Promise.resolve(state.user.data) : getUser(partnerUserId)
    ]);

    logger.setContext({ userId: partnerUserId, partnerId });

    thunkAPI.dispatch(getPartnerStatsThunk(partner.id));

    // window.analytics.identify(user.id.toString(), {
    //   createdAt: user.createdAt,
    //   isAdmin: isAdmin(user),
    //   isOrganizer: isOrganizer(user),
    //   firstName: user.firstName,
    //   lastName: user.lastName,
    //   avatar: user.imageUrl,
    //   company: {
    //     id: partner.id,
    //     name: partner.name
    //   },
    //   email: user.email
    // });

    // window.analytics.group(company.id.toString(), {
    //   name: company.name
    // });

    // window.analytics.track('initialized_dashboard');

    logger.info('Bootstraping successed');

    return { partner, user };
  } catch (error) {
    logger.error('Bootstraping failed', { error: error.message });

    thunkAPI.dispatch(logout());

    return thunkAPI.rejectWithValue({ user: null, partner: null });
  }
});
import { VStack } from '@chakra-ui/react';
import { PartnerDocumentJSON } from '@jurnee/common/src/entities/PartnerDocument';
import { PartnerRequestJSON } from '@jurnee/common/src/entities/PartnerRequest';
import { PropositionJSON } from '@jurnee/common/src/entities/Proposition';
import { PropositionsGroupJSON } from '@jurnee/common/src/entities/PropositionsGroup';
import { QuoteDrawerData } from 'drawers/PartnerQuoteDrawer';
import { EmptyState } from '../EmptyState';
import { PropositionCard } from './PropositionCard';

interface Props {
  partnerRequestId: PartnerRequestJSON['id'];
  propositionsGroup: PropositionsGroupJSON;
  propositions: PropositionJSON[];
  quotes: PartnerDocumentJSON[];
  isEditable: boolean;
  onUpsert(data: QuoteDrawerData): void;
  onDelete(data: { propositionId: PropositionJSON['id']; quoteId: PartnerDocumentJSON['id'] }): void;
}

export function PropositionsList(props: Props) {
  if (props.propositions.length === 0) {
    return <EmptyState
      bookingId={props.propositionsGroup.bookingId}
      partnerRequestId={props.partnerRequestId}
      onUpsert={props.onUpsert}
    />;
  }

  return (
    <VStack w="100%" spacing={4}>
      {
        props.propositions.map(proposition =>
          <PropositionCard
            key={proposition.id}
            partnerRequestId={props.partnerRequestId}
            propositionsGroup={props.propositionsGroup}
            proposition={proposition}
            quotes={props.quotes}
            isEditable={props.isEditable}
            onUpsert={props.onUpsert}
            onDelete={props.onDelete}
          />
        )
      }
    </VStack>
  );
}
import { createSlice } from '@reduxjs/toolkit';
import { createPartnerRequestCommentThunk, getPartnerRequestCommentsThunk } from 'store/partnerRequestComments/partnerRequestComments.thunks';
import { partnerUsersAdapter } from './partnerUsers.adapter';

const slice = createSlice({
  name: 'partnerUsers',
  initialState: partnerUsersAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getPartnerRequestCommentsThunk.fulfilled, (state, { payload: { relationships: { partnersUsers } } }) => {
      partnerUsersAdapter.setMany(state, partnersUsers);
    });

    builder.addCase(createPartnerRequestCommentThunk.fulfilled, (state, { payload: { relationships: { partnersUsers } } }) => {
      partnerUsersAdapter.setMany(state, partnersUsers);
    });

  }
});

export default slice.reducer;
import { Box, HStack, Heading, VStack, useToast } from '@chakra-ui/react';
import { Loader } from '@jurnee/common/src/components/Loader';
import { BackButton } from '@jurnee/common/src/components/buttons/BackButton';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { getErrorToast } from '@jurnee/common/src/utils/toasts';
import { getBooking } from 'api/bookings';
import { PayoutInformationCard } from 'components/PayoutInformationCard';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, Params, useParams } from 'react-router-dom';
import { getPartnerSelector } from 'store/partner/partner.selectors';
import { BookingItems } from './BookingItems';
import { Invoices } from './Invoices';

export function Booking() {
  const toast = useToast();
  const { t } = useTranslation('booking');

  const params = useParams<Params<'bookingId'>>();
  const bookingId = Number(params.bookingId);

  const partner = useSelector(getPartnerSelector);
  const [booking, setBooking] = useState<BookingJSON>(null);
  const [isBookingLoading, setIsBookingLoading] = useState(true);

  async function fetchBooking() {
    const booking = await getBooking(partner.id, bookingId);
    setBooking(booking);
    setIsBookingLoading(false);
  }

  useEffect(() => {
    try {
      fetchBooking();
    } catch (err) {
      toast(getErrorToast(t('toasts.fetchBooking.error')));
    }
  }, []);

  if (isBookingLoading) {
    return <Loader />;
  }

  return (
    <VStack w="100%" h="100%" spacing={0}>
      <Box w="100%" p={8} bg="white" borderBottom="1px solid" borderColor="gray.200">
        <HStack spacing={4}>
          <Link to="/bookings">
            <BackButton />
          </Link>

          <Heading lineHeight="32px">{t('heading')} #{booking.id}</Heading>
        </HStack>
      </Box>

      <HStack w="100%" maxW="1184px" alignSelf="center" p={8} spacing={8} alignItems="flex-start">
        <VStack w="66%" minW={400} spacing={6}>
          <BookingItems bookingItems={booking.bookingsItems} />

          <Invoices bookingId={booking.id} />
        </VStack>

        <VStack w="33%" minW={340} spacing={8}>
          <PayoutInformationCard />
        </VStack>
      </HStack>
    </VStack>
  );
}
{
  "createPackage": "Create package",
  "dotMenu": {
    "delete": "Delete package",
    "deleteConfirmation": {
      "message": "Are you sure you want to delete <strong>{{name}}</strong> package?",
      "title": "Delete package"
    }
  },
  "drawer": {
    "description": "Fill in packages or options information to offer to customers so they can submit a quote request.",
    "form": {
      "maxParticipants": {
        "label": "Maximum participants"
      }
    },
    "maxLength": "{{count}} / {{max}}",
    "title": {
      "create": "Create a package",
      "edit": "Edit package"
    }
  },
  "emptyState": {
    "description": "Start crafting your packages to showcase the various options and pricing you offer",
    "heading": "You don't have any packages yet"
  },
  "fields": {
    "description": "Description",
    "maxParticipants": "Max. participants",
    "name": "Name"
  },
  "toasts": {
    "create": {
      "error": "An error occurred while submitting your package",
      "success": "Package has been successfully created"
    },
    "delete": {
      "error": "An error occurred while deleting your package",
      "success": "Package has been successfully deleted"
    },
    "update": {
      "error": "An error occurred while updating your package",
      "success": "Package has been successfully updated"
    }
  }
}
import { Card, HStack, Heading, Text, VStack, useToast } from '@chakra-ui/react';
import { EmptyState } from '@jurnee/common/src/components/EmptyState';
import { Loader } from '@jurnee/common/src/components/Loader';
import { PartnerDocumentJSON } from '@jurnee/common/src/entities/PartnerDocument';
import { PartnerRequestJSON } from '@jurnee/common/src/entities/PartnerRequest';
import { PropositionJSON } from '@jurnee/common/src/entities/Proposition';
import { sortByDate } from '@jurnee/common/src/utils/arrays';
import { isPropositionsGroupDraft } from '@jurnee/common/src/utils/propositionsGroups';
import { getErrorToast } from '@jurnee/common/src/utils/toasts';
import { getPartnerRequestPropositions } from 'api/partnersRequests';
import { PartnerQuoteDrawer, QuoteDrawerData } from 'drawers/PartnerQuoteDrawer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getPartnerSelector } from 'store/partner/partner.selectors';
import { PropositionsList } from './PropositionsList';

interface Props {
  partnerRequest: PartnerRequestJSON;
  isEditable: boolean;
}

export function Propositions({ partnerRequest, isEditable }: Props) {
  const toast = useToast();
  const { t } = useTranslation('request');

  const partner = useSelector(getPartnerSelector);
  const [propositions, setPropositions] = useState<PropositionJSON[]>([]);
  const [quotes, setQuotes] = useState<PartnerDocumentJSON[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  async function fetchPropositions() {
    try {
      const { list, relationships: { partnersDocuments }} = await getPartnerRequestPropositions({
        partnerId: partner.id,
        partnerRequestId: partnerRequest.id
      });

      setPropositions(list);
      setQuotes(partnersDocuments);
      setIsLoading(false);
    } catch(err) {
      toast(getErrorToast(t('toasts.fetchPartnerRequest.error')));
    }
  }

  useEffect(() => {
    fetchPropositions();
  }, [partnerRequest.id]);

  function onUpsert(data: QuoteDrawerData) {
    const filteredPropositions = propositions.filter(({ id }) => id !== data.proposition.id);
    const sortedPropositions = sortByDate([...filteredPropositions, data.proposition], 'createdAt');
    setPropositions(sortedPropositions);

    const filteredQuotes = quotes.filter(({ id }) => id !== data.quote.id);
    setQuotes([...filteredQuotes, data.quote]);
  }

  function onDelete(data: { propositionId: PropositionJSON['id']; quoteId: PartnerDocumentJSON['id'] }) {
    setPropositions(propositions.filter(({ id }) => id !== data.propositionId));
    setQuotes(quotes.filter(({ id }) => id !== data.quoteId));
  }

  function render() {
    if (isLoading) {
      return <Loader
        w="100%"
        px={5}
        py="80px"
        bg="white"
        border="1px solid"
        borderColor="gray.200"
        borderRadius={4}
      />;
    }

    if (partnerRequest.declinedAt) {
      return (
        <Card py={8}>
          <EmptyState
            icon="calendarOff"
            heading={t('declinedState.heading')}
            description={t('declinedState.description')}
          />
        </Card>
      );
    }

    if (
      !isPropositionsGroupDraft(partnerRequest.propositionsGroup)
        && propositions.length === 0
    ) {
      return (
        <Card py={8}>
          <EmptyState
            icon="calendarOff"
            heading={t('endedState.heading')}
            description={t('endedState.description')}
          />
        </Card>
      );
    }

    return <PropositionsList
      partnerRequestId={partnerRequest.id}
      propositionsGroup={partnerRequest.propositionsGroup}
      propositions={propositions}
      quotes={quotes}
      isEditable={isEditable}
      onUpsert={onUpsert}
      onDelete={onDelete}
    />;
  }

  const hasCreateQuoteLink = quotes.length > 0 && isEditable;

  return (
    <VStack w="100%" spacing={2} alignItems="flex-start">
      <HStack w="100%" justifyContent="space-between">
        <Heading size="md">{t('propositions.title')}</Heading>

        {
          hasCreateQuoteLink &&
            <PartnerQuoteDrawer
              key={new Date().getTime()}
              bookingId={partnerRequest.propositionsGroup.bookingId}
              partnerRequestId={partnerRequest.id}
              onUpsert={onUpsert}
            >
              <Text variant="link">
                { t('propositions.createQuote') }
              </Text>
            </PartnerQuoteDrawer>
        }
      </HStack>

      { render() }
    </VStack>
  );
}
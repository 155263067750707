import { Td, Tr } from '@chakra-ui/react';
import { PartnerRequestJSON } from '@jurnee/common/src/entities/PartnerRequest';
import { formatDate } from '@jurnee/common/src/utils/dates';
import { getPropositionsGroupTimezone } from '@jurnee/common/src/utils/propositionsGroups';
import { GroupedRequest } from 'pages/Requests';
import { useNavigate } from 'react-router-dom';
import { DotMenu } from './DotMenu';
import { Status } from './Status';

interface Props {
  request: GroupedRequest;
  onDecline(partnerRequest: PartnerRequestJSON, reason: string): Promise<void>;
}

export function Row({ request, onDecline }: Props) {
  const navigate = useNavigate();

  const { partnerRequest } = request;

  const date = formatDate(
    new Date(partnerRequest.propositionsGroup.from),
    {
      displayTime: true,
      displayTimeZone: true,
      timeZone: getPropositionsGroupTimezone(partnerRequest.propositionsGroup)
    }
  );

  return (
    <Tr cursor="pointer" onClick={() => navigate(`/requests/${partnerRequest.id}`)}>
      <Td>#{partnerRequest.id}</Td>
      <Td>{date}</Td>
      <Td>{partnerRequest.propositionsGroup.participants || '-'}</Td>
      <Td>{partnerRequest.propositionsGroup.address ? partnerRequest.propositionsGroup.address.city : '-'}</Td>
      <Td><Status request={request} /></Td>
      <Td pl={0} pr={3} textAlign="right">
        <DotMenu
          partnerRequest={partnerRequest}
          onDecline={onDecline}
        />
      </Td>
    </Tr>
  );
}
import { HStack, Text, VStack } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { SecondaryButton } from '@jurnee/common/src/components/buttons/SecondaryButton';
import { PartnerPaymentDocumentJSON } from '@jurnee/common/src/entities/PartnerPaymentDocument';
import { formatDate } from '@jurnee/common/src/utils/dates';
import { formatPrice } from '@jurnee/common/src/utils/prices';
import { useTranslation } from 'react-i18next';

interface Props {
  paymentDocument: PartnerPaymentDocumentJSON;
  onDownload(paymentDocumentId: PartnerPaymentDocumentJSON['id']): void;
}

export function PaymentDocumentLine({ paymentDocument, onDownload }: Props) {
  const { t } = useTranslation('invoice', { keyPrefix: 'paymentDocument' });

  const amount = formatPrice(
    paymentDocument.amount,
    paymentDocument.currencyId,
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  );

  return (
    <HStack w="100%" justifyContent="space-between">
      <VStack w="30%" spacing={0} alignItems="flex-start">
        <Text fontWeight={700}>{t('paidBy')}</Text>
        <Text color="gray.400">{t(`type.${paymentDocument.type}`)}</Text>
      </VStack>

      <VStack w="30%" spacing={0} alignItems="flex-start">
        <Text fontWeight={700}>{t('paidAt')}</Text>
        <Text color="gray.400">{formatDate(new Date(paymentDocument.paidAt))}</Text>
      </VStack>

      <VStack w="30%" spacing={0} alignItems="flex-start">
        <Text fontWeight={700}>{t('amount')}</Text>
        <Text color="gray.400">{amount}</Text>
      </VStack>

      <SecondaryButton
        w="32px"
        h="32px"
        minW="32px"
        borderRadius={4}
        colorScheme="gray"
        onClick={() => onDownload(paymentDocument.id)}
      >
        <Icon icon="download" size={5} />
      </SecondaryButton>
    </HStack>
  );
}
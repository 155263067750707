import { Box, HStack, Heading, VStack, useToast } from '@chakra-ui/react';
import { InputFormControl } from '@jurnee/common/src/components/InputFormControl';
import { TextareaFormControl } from '@jurnee/common/src/components/TextareaFormControl';
import { PrimaryButton } from '@jurnee/common/src/components/buttons/PrimaryButton';
import { PlaceDTO } from '@jurnee/common/src/dtos/places';
import { PartnerProviderInformationCreate } from '@jurnee/common/src/entities/PartnerProviderInformation';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { AddressForm } from 'components/AddressForm';
import { GoogleMapsAccount } from 'components/GoogleMapsAccount';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { getPartnerSelector } from 'store/partner/partner.selectors';
import { createPartnerAddressThunk, createPartnerProviderInformationThunk, updatePartnerAddressThunk, updatePartnerThunk } from 'store/partner/partner.thunks';

export function Account() {
  const dispatch = useAppDispatch();
  const partner = useSelector(getPartnerSelector);
  const toast = useToast();
  const { t } = useTranslation(['common', 'toasts', 'account']);

  const [name, setName] = useState(partner.name);
  const [description, setDescription] = useState(partner.description);
  const [phoneNumber, setPhoneNumber] = useState(partner.phoneNumber);
  const [email, setEmail] = useState(partner.email);
  const [website, setWebsite] = useState(partner.website);
  const [providerInformation, setProviderInformation] = useState<PartnerProviderInformationCreate>(null);

  const [address, setAddress] = useState({
    address: partner.address?.address ?? null,
    city: partner.address?.city ?? null,
    country: partner.address?.country ?? null,
    countryCode: partner.address?.countryCode ?? null,
    postalCode: partner.address?.postalCode ?? null,
    state: partner.address?.state ?? null
  });

  const existingProviderInformation = partner.partnersProvidersInformation.find(({ provider }) => {
    return provider === 'GOOGLE_MAPS';
  });

  function savePartner() {
    const data = { email, description, name, phoneNumber, website };

    return dispatch(updatePartnerThunk({
      id: partner.id,
      data
    }));
  }

  function saveAddress() {
    const { countryCode, ...data } = address;

    if (partner.address) {
      return dispatch(updatePartnerAddressThunk({
        id: partner.id,
        addressId: partner.address.id,
        data
      }));
    } else {
      return dispatch(createPartnerAddressThunk({
        id: partner.id,
        data
      }));
    }
  }

  function savePartnerInformation() {
    return dispatch(createPartnerProviderInformationThunk({
      id: partner.id,
      data: providerInformation
    }));
  }

  async function onSave() {
    try {
      if (existingProviderInformation === undefined && providerInformation) {
        await savePartnerInformation().unwrap();
      }

      await Promise.all([
        savePartner().unwrap(),
        saveAddress().unwrap()
      ]);

      toast(getSuccessToast(t('toasts:accountUpdate.success')));
    } catch (error) {
      toast(getErrorToast(t('toasts:accountUpdate.error'), error.message));
    }
  }

  function onPlaceChange(place: PlaceDTO) {
    setName(place.details.name);
    setAddress({
      address: place.details.address.street,
      city: place.details.address.city,
      country: place.details.address.country,
      countryCode: place.details.address.countryCode,
      postalCode: place.details.address.postalCode,
      state: null
    });
    setPhoneNumber(place.details.phoneNumber.replace(/-/g, ' '));
    setWebsite(place.details.website);
    setProviderInformation({
      partnerId: partner.id,
      provider: 'GOOGLE_MAPS',
      providerId: place.id,
      ratingAverage: place.details.rating.average,
      ratingCount: place.details.rating.count,
      url: place.details.url
    });
  }

  return (
    <VStack w="100%" h="100%" spacing={0}>
      <Box w="100%" p={8} bg="white" borderBottom="1px solid" borderColor="gray.200">
        <Heading lineHeight="32px">{t('account:heading')}</Heading>
      </Box>

      <HStack w="100%" maxW="1184px" p={8} h="100%" alignSelf="center" spacing={8} justifyContent="center">
        <VStack w="50%" spacing={5} h="100%">
          <InputFormControl size="sm" name="name" value={name} onChange={setName} isRequired/>

          <AddressForm address={address} onChange={setAddress} />

          <HStack w="100%" spacing={5}>
            <InputFormControl size="sm" name="phoneNumber" value={phoneNumber} onChange={setPhoneNumber} isRequired />
            <InputFormControl size="sm" name="email" type="email" value={email} onChange={setEmail} isRequired />
          </HStack>
          <InputFormControl size="sm" name="website" type="url" value={website} onChange={setWebsite} />
        </VStack>

        <VStack w="50%" spacing={8} h="100%" pt={6}>
          <GoogleMapsAccount providerInformation={existingProviderInformation} onChange={onPlaceChange}/>
          <TextareaFormControl name="description" h={246} value={description} onChange={setDescription} isRequired/>
        </VStack>
      </HStack>

      <HStack w="100%" px={8} py={4} bg="white" borderTop="1px solid" borderColor="gray.200" position="sticky" bottom={0} justifyContent="flex-end">
        <PrimaryButton colorScheme="teal" size="sm" onClick={onSave}>{t('common:buttons.save')}</PrimaryButton>
      </HStack>
    </VStack>
  );
}
{
  "heading": "Bookings",
  "tabs": {
    "past": {
      "emptyState": {
        "description": "You don't have any past bookings at the moment, but the next ones will appear here",
        "heading": "No past bookings"
      },
      "label": "Past"
    },
    "upcoming": {
      "emptyState": {
        "description": "You don't have any bookings at the moment, but the next ones will appear here",
        "heading": "No bookings planned"
      },
      "label": "Upcoming"
    }
  },
  "toasts": {
    "fetchBookings": {
      "error": "An error occurred while retrieving your bookings"
    }
  }
}
import { PartnerStatsJSON } from '@jurnee/common/src/dtos/partners';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPartnerStats } from 'api/partner';
import { RootState } from 'store';

export const getPartnerStatsThunk = createAsyncThunk<PartnerStatsJSON, number, { state: RootState }>('PARTNER_STATS_FETCH', async (partnerId, thunkAPI) => {
  try {
    const stats = await getPartnerStats(partnerId);
    return stats;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});
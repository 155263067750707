import { Td, Text, Tr, useDisclosure } from '@chakra-ui/react';
import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { ProductJSON } from '@jurnee/common/src/entities/Product';
import { getHighestThresholdPricing } from '@jurnee/common/src/utils/products';
import { ProductDrawer } from 'drawers/ProductDrawer';
import { useMemo } from 'react';
import { DotMenu } from './DotMenu';

interface Props {
  experience: ExperienceJSON;
  product: ProductJSON;
  onUpsert(product: ProductJSON): void;
  onDelete(product: ProductJSON): void;
}

export function Row({ experience, product, onUpsert, onDelete }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const pricing = useMemo(() => product && getHighestThresholdPricing(product), [product]);

  return (
    <Tr key={product.id} cursor="pointer" onClick={onOpen}>
      <Td maxW={1}><Text isTruncated>{product.name}</Text></Td>
      <Td maxW={1}><Text isTruncated>{product.description}</Text></Td>
      <Td>{pricing?.threshold || '-'}</Td>
      <Td pl={0} pr={3} textAlign="right">
        <DotMenu
          product={product}
          isDeletable={experience.status === 'UNAVAILABLE'}
          onDelete={onDelete}
        />
      </Td>
      <Td hidden>
        <ProductDrawer
          key={product.id}
          experienceId={experience.id}
          product={product}
          pricing={pricing}
          isOpen={isOpen}
          onClose={onClose}
          onUpsert={onUpsert}
        />
      </Td>
    </Tr>
  );
}
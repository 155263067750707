{
  "dotMenu": {
    "delete": {
      "label": "Delete quote",
      "modal": {
        "message": "Are you sure you want to delete this quote?",
        "title": "Delete quote"
      },
      "toasts": {
        "error": "An error occurred while deleting the quote",
        "success": "Quote deleted"
      }
    },
    "edit": {
      "label": "Edit quote"
    }
  },
  "drawer": {
    "form": {
      "amount": {
        "label": "Total price including taxes",
        "placeholder": "1234.56"
      },
      "comment": {
        "placeholder": "Please add any useful information to your quote"
      },
      "document": {
        "label": "Quote document"
      },
      "expiredAt": {
        "label": "Expiration date",
        "placeholder": "Select a date"
      },
      "name": {
        "placeholder": "Name of your proposal"
      },
      "toasts": {
        "error": "An error occurred while uploading the quote",
        "success": "Quote uploaded"
      }
    },
    "title": {
      "create": "Create quote",
      "edit": "Edit quote"
    }
  },
  "quote": {
    "amount": "Amount",
    "expiration": "Expiration",
    "status": {
      "label": "Status",
      "value": {
        "PENDING": "Pending",
        "REJECTED": "Rejected",
        "SELECTED": "Selected"
      }
    },
    "toasts": {
      "download": {
        "error": "An error occurred while downloading the quote"
      }
    }
  }
}
{
  "dotMenu": {
    "edit": {
      "label": "Edit service"
    }
  },
  "drawers": {
    "create": {
      "title": "Create a service",
      "toasts": {
        "error": "An error occurred while submitting your service",
        "success": "Service has been successfully created"
      }
    },
    "edit": {
      "title": "Edit service",
      "toasts": {
        "error": "An error occurred while updating your service",
        "success": "Service has been successfully updated"
      }
    },
    "form": {
      "name": {
        "maxLength": "{{count}} / {{max}}"
      },
      "type": {
        "label": "Type of service",
        "placeholder": "Select type"
      }
    }
  },
  "modals": {
    "publish": {
      "accountInfoCondition": "Fill in your account information",
      "checksInfo": "A few checks before you can publish your service:",
      "descriptionCondition": "Add a description of at least 500 characters",
      "oncePublishedInfo": "Once published your service will be reviewed by the Jurnee team before being available to everyone.",
      "packagesCondition": "Create at least 1 package",
      "photosCondition": "Upload at least 4 photos",
      "title": "Publish"
    }
  },
  "participants": "up to {{count}} people",
  "publish": "Publish",
  "status": {
    "AVAILABLE": "Online",
    "COMING_SOON": "In review",
    "UNAVAILABLE": "Draft"
  },
  "tabs": {
    "description": {
      "label": "Description",
      "maxLength": "{{count}} / {{max}}",
      "placeholder": "Use this space to provide a detailed description of your service. Include specific details about what customers can expect, any special features, and why they should choose you. The more informative and engaging your description, the more likely customers are to book your service."
    },
    "packages": {
      "label": "Packages"
    },
    "photos": {
      "addPhotos": "Add photos",
      "label": "Photos"
    },
    "preview": {
      "label": "Preview"
    }
  },
  "toasts": {
    "deleteImage": {
      "error": "An error occurred while deleting your photo"
    },
    "fetchExperience": {
      "error": "An error occurred while retrieving your service"
    },
    "fetchExperienceStats": {
      "error": "An error occurred while retrieving service reviews"
    },
    "fetchLanguages": {
      "error": "An error occurred while retrieving languages"
    },
    "publishExperience": {
      "error": "An error occurred while submitting your service for review",
      "success": "Service review request has been successfully submitted"
    },
    "updateExperience": {
      "error": "An error occurred while updating your service",
      "success": "Service has been successfully updated"
    },
    "updateImages": {
      "error": "An error occurred while updating photos rank"
    },
    "uploadImage": {
      "error": "An error occurred while uploading your photo"
    }
  },
  "type": {
    "BAR": "Bar",
    "BOX_SHIPPING": "Box shipping",
    "CATERING": "Catering",
    "IN_OFFICE": "Office animation",
    "IN_PERSON": "In person activity",
    "MEETING_ROOM": "Meeting room",
    "RESTAURANT": "Restaurant",
    "VENUE": "Venue",
    "VIRTUAL": "Virtual"
  }
}
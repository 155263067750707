import { TabPanel, Table, TableContainer, Th, Thead, Tr } from '@chakra-ui/react';
import { PartnerRequestJSON } from '@jurnee/common/src/entities/PartnerRequest';
import { useTranslation } from 'react-i18next';
import { GroupedRequest, TabKey } from '..';
import { Body } from './Body';

interface Props {
  tab: TabKey;
  requests: GroupedRequest[];
  onDecline(partnerRequest: PartnerRequestJSON, reason: string): Promise<void>;
}

export function RequestsTable(props: Props) {
  const { t } = useTranslation('request', { keyPrefix: 'fields' });

  return (
    <TabPanel>
      <TableContainer w="100%">
        <Table>
          <Thead>
            <Tr>
              <Th w="15%">{t('reference')}</Th>
              <Th w="25%">{t('eventDate')}</Th>
              <Th w="20%">{t('participants')}</Th>
              <Th w="20%">{t('city')}</Th>
              <Th w="20%">{t('status')}</Th>
              <Th w={8}></Th>
            </Tr>
          </Thead>

          <Body {...props} />
        </Table>
      </TableContainer>
    </TabPanel>
  );
}
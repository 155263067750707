import { Box, Heading, Tab, TabList, TabPanel, TabPanels, Tabs, VStack, useToast } from '@chakra-ui/react';
import { Loader } from '@jurnee/common/src/components/Loader';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { PartnerDocumentJSON } from '@jurnee/common/src/entities/PartnerDocument';
import { sortByDate } from '@jurnee/common/src/utils/arrays';
import { getDefaultTabIndex } from '@jurnee/common/src/utils/tabs';
import { getErrorToast } from '@jurnee/common/src/utils/toasts';
import { getBookings } from 'api/bookings';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getPartnerSelector } from 'store/partner/partner.selectors';
import { router } from '../../router';
import { BookingsCalendar } from './BookingsCalendar';

export type TabKey = 'upcoming' | 'past';

export type GroupedBookings = Record<TabKey, Record<number, BookingJSON[]>>;

export function Bookings() {
  const toast = useToast();
  const [, setSearchParams] = useSearchParams();
  const { t } = useTranslation('bookings');

  const partner = useSelector(getPartnerSelector);

  const [bookings, setBookings] = useState<BookingJSON[]>([]);
  const [partnerDocuments, setPartnerDocuments] = useState<PartnerDocumentJSON[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  const groupedBookings = useMemo(
    () => bookings.reduce((out, booking) => {
      const [bookingItem] = sortByDate(booking.bookingsItems, 'from');

      if (!bookingItem) {
        return out;
      }

      const date = new Date(bookingItem.from);
      const key = date >= new Date() ? 'upcoming' : 'past';
      const month = new Date(date.getFullYear(), date.getMonth()).getTime();

      if (Array.isArray(out[key][month])) {
        out[key][month].push(booking);
      } else {
        out[key][month] = [booking];
      }

      return out;
    }, { 'upcoming': [], 'past': [] } as GroupedBookings),
    [bookings]
  );

  const tabs = [{
    label: `${t('tabs.upcoming.label')}`,
    key: 'upcoming'
  }, {
    label: `${t('tabs.past.label')}`,
    key: 'past'
  }];

  async function fetchBookings() {
    try {
      const { list, relationships: { partnersDocuments } } = await getBookings(partner.id);
      setBookings(list);
      setPartnerDocuments(partnersDocuments);
    } catch(error) {
      toast(getErrorToast(t('toasts.fetchBookings.error')));
    }

    setIsLoading(false);
  }

  useEffect(() => {
    fetchBookings();
  }, []);

  return (
    <Tabs defaultIndex={getDefaultTabIndex(tabs, router.state.location.search)} onChange={index => setSearchParams({ tab: tabs[index].key })}>
      <VStack w="100%" h="100%" spacing={0}>
        <Box w="100%" bg="white">
          <Heading w="100%" lineHeight="32px" p={8}>{t('heading')}</Heading>

          <TabList>
            { tabs.map(tab => (<Tab key={tab.key}>{tab.label}</Tab>)) }
          </TabList>
        </Box>

        {
          isLoading ? (
            <Loader h={400} />
          ) : (
            <TabPanels>
              <TabPanel>
                <BookingsCalendar tab='upcoming' months={groupedBookings.upcoming} partnerDocuments={partnerDocuments} />
              </TabPanel>

              <TabPanel>
                <BookingsCalendar tab='past' months={groupedBookings.past} partnerDocuments={partnerDocuments} />
              </TabPanel>
            </TabPanels>
          )
        }
      </VStack>
    </Tabs>
  );
}
import { ChakraProvider } from '@chakra-ui/react';
import theme from '@jurnee/common/src/theme';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import './App.css';
import './locales/config';
import { router } from './router';
import { store } from './store';

const root = createRoot(document.getElementById('app'));

root.render(
  <ChakraProvider theme={theme}>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </ChakraProvider>
);
import { Grid, TabPanel, useToast } from '@chakra-ui/react';
import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { ExperienceImageJSON } from '@jurnee/common/src/entities/ExperienceImage';
import { getCdnImageUrl } from '@jurnee/common/src/utils/core';
import { getErrorToast } from '@jurnee/common/src/utils/toasts';
import { deleteExperienceImage, updateExperienceImages, uploadExperienceImage } from 'api/experiences';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageCard } from './ImageCard';
import { ImageInput } from './ImageInput';

interface Props {
  experience: ExperienceJSON;
  onChange(experience: ExperienceJSON): void;
}

export function Photos(props: Props) {
  const toast = useToast();
  const { t } = useTranslation('service');

  const [experienceImages, setExperienceImages] = useState(props.experience.experiencesImages);
  const [files, setFiles] = useState<File[]>([]);
  const [draggedIndex, setDraggedIndex] = useState(null);

  const images = useMemo(
    () => {
      const arePhotosDeletable = experienceImages.length > 4;

      return experienceImages.map(({ id, image }, i) => {
        const styleProps = i === draggedIndex ? { opacity: '30%' } : {};

        return <ImageCard
          key={id}
          url={getCdnImageUrl(image.path)}
          isLoading={false}
          onDelete={arePhotosDeletable ? () => onDelete(id) : null}
          onDragStart={() => setDraggedIndex(i)}
          onDragEnter={() => handleDragEnter(i)}
          onDragEnd={onDragEnd}
          onDragOver={e => e.preventDefault()}
          {...styleProps}
        />;
      });
    },
    [experienceImages, draggedIndex]
  );

  const uploadingImages = useMemo(
    () => files.map(file =>
      <ImageCard
        key={file.lastModified}
        url={URL.createObjectURL(file)}
        isLoading={true}
      />
    ),
    [files]
  );

  async function onChange(experiencesImages: ExperienceImageJSON[]) {
    return props.onChange({ ...props.experience, experiencesImages });
  }

  function handleDragEnter(targetIdx: number) {
    const newImages = [...experienceImages];
    const draggedImage = newImages[draggedIndex];

    if (!draggedImage) {
      return;
    }

    newImages.splice(draggedIndex, 1);
    newImages.splice(targetIdx, 0, draggedImage);

    setDraggedIndex(targetIdx);
    setExperienceImages(newImages.map((image, rank) => ({ ...image, rank })));
  }

  async function onDragEnd() {
    setDraggedIndex(null);

    try {
      const { list } = await updateExperienceImages({
        partnerId: props.experience.partner.id,
        experienceId: props.experience.id,
        body: { data: experienceImages.map(({ id }, rank) => ({ id, rank })) }
      });

      setExperienceImages(list);
      onChange(list);
    } catch(error) {
      setExperienceImages(props.experience.experiencesImages);
      toast(getErrorToast(t('toasts.updateImages.error')));
    }
  }

  async function onAdd(addedFiles: File[]) {
    setFiles([...files, ...addedFiles]);

    try {
      const uploadedImages = [];

      for (const file of addedFiles) {
        const uploadedFile = await uploadExperienceImage({
          partnerId: props.experience.partner.id,
          experienceId: props.experience.id
        }, file);

        uploadedImages.push(uploadedFile);
      }

      setFiles(files.filter(({ lastModified }) => !addedFiles.map(file => file.lastModified).includes(lastModified)));
      const newImages = [...experienceImages, ...uploadedImages];
      setExperienceImages(newImages);
      onChange(newImages);
    } catch(error) {
      toast(getErrorToast(t('toasts.uploadImage.error')));
    }
  }

  async function onDelete(experienceImageId: ExperienceImageJSON['id']) {
    try {
      const experienceImage = await deleteExperienceImage({
        partnerId: props.experience.partner.id,
        experienceId: props.experience.id,
        experienceImageId
      });

      const newImages = experienceImages.filter(({ id }) => id !== experienceImage.id);
      setExperienceImages(newImages);
      onChange(newImages);
    } catch(error) {
      toast(getErrorToast(t('toasts.deleteImage.error'), error.message));
    }
  }

  return (
    <TabPanel>
      <Grid gap={8} gridTemplateColumns="repeat(auto-fill, minmax(140px, 1fr))">
        { images }
        { uploadingImages }
        <ImageInput onAdd={onAdd} />
      </Grid>
    </TabPanel>
  );
}
import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormLabel, HStack, Input, VStack, useToast } from '@chakra-ui/react';
import AmountInput, { AmountInputData } from '@jurnee/common/src/components/AmountInput';
import { DatePicker } from '@jurnee/common/src/components/DatePicker';
import { FileInput } from '@jurnee/common/src/components/FileInput';
import { Loader } from '@jurnee/common/src/components/Loader';
import { PrimaryButton } from '@jurnee/common/src/components/buttons/PrimaryButton';
import { SecondaryButton } from '@jurnee/common/src/components/buttons/SecondaryButton';
import { DOCUMENT_CONTENT_TYPES } from '@jurnee/common/src/constants/ContentTypes';
import { PartnerDocumentUpsertBody } from '@jurnee/common/src/dtos/partnersDocuments';
import { DEFAULT_UTC_TIMEZONE } from '@jurnee/common/src/entities/Address';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { PartnerDocumentJSON } from '@jurnee/common/src/entities/PartnerDocument';
import { convertToTimezone, getCurrentTimeZone } from '@jurnee/common/src/utils/dates';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { createPartnerDocument, uploadPartnerDocument } from 'api/partnerDocuments';
import { cloneElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { getCurrenciesFetchStatusSelector, getCurrenciesKeys } from 'store/currencies/currencies.selector';
import { getCurrenciesThunk } from 'store/currencies/currencies.thunks';
import { getPartnerSelector } from 'store/partner/partner.selectors';

interface Props {
  bookingId: BookingJSON['id'];
  children: React.ReactElement;
  onCreate(partnerDocument: PartnerDocumentJSON): void;
}

export function PartnerInvoiceDrawer(props: Props) {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { t } = useTranslation(['invoice', 'common']);

  const partner = useSelector(getPartnerSelector);
  const currencies = useSelector(getCurrenciesKeys);
  const currenciesFetchStatus = useSelector(getCurrenciesFetchStatusSelector);

  const [isOpen, setIsOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [amount, setAmount] = useState<number>(null);
  const [currencyId, setCurrencyId] = useState('EUR');
  const [dueAt, setDueAt] = useState<Date>(null);
  const [documentReference, setDocumentReference] = useState<string>(null);

  const [partnerDocument, setPartnerDocument] = useState<File>(null);

  const isAmountInvalid = amount !== null && amount <= 0;
  const isSaveDisabled = !amount || isAmountInvalid || !partnerDocument || !dueAt;
  const isLoading = currenciesFetchStatus !== 'FETCHED';

  const body: Omit<PartnerDocumentUpsertBody, 'path'> = {
    amount,
    currencyId,
    dueAt: convertToTimezone(dueAt, getCurrentTimeZone(), DEFAULT_UTC_TIMEZONE),
    documentReference,
    filename: partnerDocument?.name,
    size: partnerDocument?.size,
    type: 'INVOICE'
  };

  function onOpen() {
    setIsOpen(true);

    if (currenciesFetchStatus !== 'FETCHED') {
      dispatch(getCurrenciesThunk());
    }
  }

  function onClose() {
    setIsOpen(false);
  }

  async function onSave() {
    setIsSaving(true);

    try {
      const { path } = await uploadPartnerDocument(partner.id, props.bookingId, partnerDocument);
      const document = await createPartnerDocument(partner.id, props.bookingId, { ...body, path });

      onClose();
      props.onCreate(document);
      toast(getSuccessToast(t('drawer.toasts.success')));
    } catch(err) {
      toast(getErrorToast(t('drawer.toasts.error')));
    }

    setIsSaving(false);
  }

  function onAmountChange({ amount, currency }: AmountInputData) {
    setAmount(amount);
    setCurrencyId(currency);
  }

  function onDateChange(date: Date) {
    if (!date) {
      return;
    }

    setDueAt(date);
  }

  return (
    <>
      { cloneElement(props.children, { onClick: onOpen }) }

      <Drawer isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            { t('drawer.title') }
            <DrawerCloseButton />
          </DrawerHeader>

          <DrawerBody p={5}>
            {
              isLoading ? (
                <Loader />
              ) : (
                <VStack spacing={5}>
                  <FormControl id="amount" isRequired>
                    <FormLabel>{t('drawer.form.amount.label')}</FormLabel>

                    <AmountInput
                      placeholder={t('drawer.form.amount.placeholder')}
                      currencies={currencies}
                      onChange={onAmountChange}
                      isInvalid={isAmountInvalid}
                    />
                  </FormControl>

                  <FormControl id="dueDate" isRequired={true}>
                    <FormLabel>{t('drawer.form.dueDate.label')}</FormLabel>

                    <DatePicker
                      popperPlacement="top"
                      dateFormat="dd MMM yyyy"
                      selected={dueAt}
                      placeholderText={t('drawer.form.dueDate.placeholder')}
                      onChange={onDateChange}
                      inputProps={{ size: 'sm' }}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>{t('drawer.form.reference.label')}</FormLabel>

                    <Input
                      size="sm"
                      name="reference"
                      value={documentReference || ''}
                      placeholder={t('drawer.form.reference.placeholder')}
                      onChange={(e) => setDocumentReference(e.target.value)}
                    />
                  </FormControl>

                  <FormControl id="amount" isRequired>
                    <FormLabel>{t('drawer.form.document.label')}</FormLabel>

                    <FileInput
                      label={t('common:fields.fileDocument.label')}
                      sublabel={t('common:fields.fileDocument.documentTypes')}
                      contentTypes={DOCUMENT_CONTENT_TYPES}
                      imageMaxWidth={1200}
                      onChange={setPartnerDocument}
                      onRemove={() => setPartnerDocument(null)}
                    />
                  </FormControl>
                </VStack>
              )
            }
          </DrawerBody>

          <DrawerFooter>
            <HStack justifyContent="space-between" w="100%">
              <SecondaryButton size="sm" colorScheme="pink" onClick={onClose}>
                { t('common:buttons.close') }
              </SecondaryButton>

              <PrimaryButton size="sm" colorScheme="teal" onClick={onSave} isLoading={isSaving} isDisabled={isSaveDisabled}>
                { t('common:buttons.save') }
              </PrimaryButton>
            </HStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
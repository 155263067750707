import { FormControl, FormLabel, Select } from '@chakra-ui/react';
import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { useTranslation } from 'react-i18next';

interface Props {
  value: ExperienceJSON['type'];
  onChange(type: ExperienceJSON['type']): void;
}

export function TypeSelector({ value, onChange }: Props) {
  const { t } = useTranslation('service');

  return (
    <FormControl id="type" isRequired>
      <FormLabel>{t('drawers.form.type.label')}</FormLabel>

      <Select size="sm" bgColor="white" value={value ?? ''} onChange={e => onChange(e.target.value as ExperienceJSON['type'])}>
        {
          [
            <option key={0} value="">{t('drawers.form.type.placeholder')}</option>,
            ([
              'BAR',
              'BOX_SHIPPING',
              'CATERING',
              'IN_OFFICE',
              'IN_PERSON',
              'MEETING_ROOM',
              'RESTAURANT',
              'VENUE',
              'VIRTUAL',
            ] as ExperienceJSON['type'][]).map(type =>
              <option key={type} value={type}>{t(`type.${type}`)}</option>
            )
          ]
        }
      </Select>
    </FormControl>
  );
}
import { StatusBadge } from '@jurnee/common/src/components/StatusBadge';
import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { useTranslation } from 'react-i18next';

interface Props {
  status: ExperienceJSON['status'];
}

function getColor(status: ExperienceJSON['status']) {
  if (status === 'AVAILABLE') {
    return 'teal';
  }

  if (status === 'COMING_SOON') {
    return 'blue';
  }

  return 'black';
}

export function ExperienceStatus({ status }: Props) {
  const { t } = useTranslation('service', { keyPrefix: 'status' });

  const color = getColor(status);

  return <StatusBadge label={t(status)} color={color} />;
}
import { createSlice } from '@reduxjs/toolkit';
import { login } from './auth.thunks';

interface State {
  token: string | undefined,
  status: 'INITIAL' | 'AUTHENTICATING' | 'AUTHENTICATED'
}

const token = localStorage.getItem('token');

const initialState: State = {
  token,
  status: typeof token === 'string' ? 'AUTHENTICATED' : 'INITIAL'
};

const reducers = {
  logout: () => {
    localStorage.removeItem('token');

    window.location.href = window.location.origin;
  }
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers,
  extraReducers: (builder) => {

    builder.addCase(login.pending, () => {
      return {
        token: undefined,
        status: 'AUTHENTICATING'
      };
    });

    builder.addCase(login.fulfilled, (state, action) => {
      return {
        token: action.payload.token,
        status: 'AUTHENTICATED'
      };
    });

  }
});

export const { logout } = slice.actions;

export default slice.reducer;
import { HStack, Text, VStack } from '@chakra-ui/react';
import { ItemAddress } from '@jurnee/common/src/components/ItemAddress';
import { BookingItemJSON } from '@jurnee/common/src/entities/BookingItem';
import { getItemTimezone } from '@jurnee/common/src/utils/bookingsItems';
import { formatDate } from '@jurnee/common/src/utils/dates';
import { useTranslation } from 'react-i18next';

interface Props {
  bookingItem: BookingItemJSON;
}

export function BookingItemCard({ bookingItem }: Props) {
  const { t } = useTranslation('booking');

  const date = formatDate(
    new Date(bookingItem.from),
    {
      displayTime: true,
      displayTimeZone: true,
      timeZone: getItemTimezone(bookingItem)
    }
  );

  return (
    <VStack w="100%" spacing={2} p={4} bg="white" border="1px solid" borderColor="gray.200" borderRadius={4}>
      <HStack w="100%" justifyContent="space-between">
        <Text fontWeight={700} lineHeight="16px" noOfLines={1}>
          { bookingItem.description }
        </Text>

        <ItemAddress
          item={bookingItem}
          color="gray.400"
          lineHeight="16px"
          noOfLines={1}
        />
      </HStack>

      <HStack w="100%" justifyContent="space-between">
        <Text lineHeight="16px">{date}</Text>
        <Text color="gray.400" lineHeight="16px">
          { t('eventSchedule.participants', { count: bookingItem.participants }) }
        </Text>
      </HStack>
    </VStack>
  );
}